<app-zen-dialog-wrapper [data]="data" (onSubmit)="submit($event)" [actionStyleCls]="'center'"
                        [errorMsg]="errorMsg" [loading]="loading">
  <div *ngIf="form && meterUsageProfileData" class="d-flex justify-content-center m-4">
    <div class="col-12 col-10" [formGroup]="form">
      <app-zen-unit-conversion-input label="Estimated Annual Usage*" [isRateField]="true"
                                     [commodity]="rcReqHelpSvc.selectedCommodity.value"
                                     [formCtrl]="form.controls.estimatedAnnualUsage"
                                     [isUsageField]="true"
                                     [defaultUtilityUnitIds]="[meterRow.unitId]"
                                     [isRateField]="false"
                                     [selectedConversionUnit]="unit"></app-zen-unit-conversion-input>
      <mat-error *ngIf="hasError('estimatedAnnualUsage', 'required')">Required Field*</mat-error>

      <mat-hint class="info w-100 d-flex" *ngIf="unit.value !== data?.data?.baseUnit">
        <div class="d-flex align-items-center justify-content-end w-100">
          <i class="material-symbols-rounded me-1">warning</i>
          Value to be converted to platform default ({{platformUsage}}  {{data?.data?.baseUnit}})
        </div>
      </mat-hint>

      <!-- NOTE: Use this PR link, in case if we wanna revert ZenIQ button -->
      <!-- https://github.com/zentility/ui-core/pull/2041 (branch DEV-10628) -->
    </div>
  </div>
</app-zen-dialog-wrapper>

<popper-content #zenIqInfoPopper>
  <app-zen-popper title="Zen IQ" [closeable]="true" [popperContent]="zenIqInfoPopper">
    <div class="my-3">
      A predictive data algorithm that uses actual bill data to provide backward and forward usage projections.
      Understanding Estimated annual usage is required for procurement and can be set manually or by adding a bill and
      enabling this feature. ZenIQ is not available when bills are not loaded.
    </div>
  </app-zen-popper>
</popper-content>
