import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../../../../../../environments';
import {Document} from '../../../_models/app-rate-check-v2';
import {map, take} from 'rxjs/operators';
import {AuthenticationService, AuthUserResponse} from '../../_services/authentication.service';
import {AbstractControl} from '@angular/forms';
import {User} from '../../../_models/user';

export interface SignupApiData {
  firstName: string;
  lastName: string;
  companyName: string;
  email: string;
  password: string;
  title: string;
}
export interface CustomerSignupEmailCheckResponse {
  result: boolean;
  errorMessage: string;
}

@Injectable({
  providedIn: 'root'
})
export class SignupService {
  baseUrl = environment.apiBaseUrl;

  constructor(private http: HttpClient,
              private authService: AuthenticationService) {
  }

  signup(data: SignupApiData): Observable<AuthUserResponse> {
    const url = `${this.baseUrl}/v2/tili/signup`;
    data.email = data.email.trim();
    data.password = btoa(data.password).replace(/\=/g, '.');
    return this.http.post<AuthUserResponse>(url, data);
  }

  sendLoeMloaToContact(contactId: string, customerId: number, bccAdvisor = false): Observable<boolean> {
    const organizationId = this.authService.getOrganizationId();
    const url = `${this.baseUrl}/v2/organizations/${organizationId}/customers/${customerId}/contacts/${contactId}/legal/loemloa/send?bccAdvisor=${bccAdvisor}`;
    return this.http.post<Document>(url, null, {observe: 'response'}).pipe(map(response => response.status === 200), take(1));
  }

  sendCustomerWelcomeEmail(customerId: number, customerEmail: string, bccAdvisor = false): Observable<boolean> {
    const organizationId = this.authService.getOrganizationId();
    customerEmail = encodeURIComponent(customerEmail);
    bccAdvisor = bccAdvisor ? true : false;
    // tslint:disable-next-line:max-line-length
    const url = `${this.baseUrl}/v2/organizations/${organizationId}/customers/${customerId}/send_welcome_email?overrideEmail=${customerEmail}&bccAdvisor=${bccAdvisor}`;
    return this.http.post<Document>(url, null, {observe: 'response'}).pipe(map(response => response.status === 200), take(1));
  }

  public signupEmailCheck(email: string, customerId?: number, isCustomerModalContext = false): Observable<CustomerSignupEmailCheckResponse> {
    const url = `${this.baseUrl}/v2/signupEmailCheck`;
    return this.http.post<CustomerSignupEmailCheckResponse>(url, {
      email: email ? email.trim() : '',
      domain: 'zentility',
      customerId,
      isCustomerModalContext
    }).pipe(take(1));
  }

  public signupRetrieveUserByEmail(email: string, customerId?: number, isCustomerModalContext = false): Observable<User> {
    const url = `${this.baseUrl}/v2/signupRetrieveUser`;
    return this.http.post<User>(url, {
      email: email ? email.trim() : '',
      domain: 'zentility',
      customerId,
      isCustomerModalContext
    }).pipe(take(1));
  }

  validateExistentAccount(emailCtrl: AbstractControl, editingEmail: string, isEditMode = false, customerId: number = null): void {
    const _email = emailCtrl.value;
    const unavailableError = 'Unavailable';
    if (emailCtrl.valid) {
      if (!isEditMode || (isEditMode && editingEmail !== _email)) {
        this.signupEmailCheck(_email, customerId).subscribe(res => {
          if (!res.result) {
            if (res.errorMessage === unavailableError) {
              emailCtrl.setErrors({existentAccount: true});
            } else {
              emailCtrl.setErrors({customerExistentAccount: true});
            }
          }
        });
      }
      // In edit mode if entered email same as existing one then we should remove the existentAccount
      if (isEditMode && editingEmail === _email) {
        emailCtrl.setErrors(null);
      }
    }
  }
}
