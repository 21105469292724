export interface SupplierProgress {
  allSupplierCount: number;
  currentSupplierCount: number;
  currentSupplierIds: string[];
  allSupplierIds: string[];
}

export interface DataProgress {
  supplierName: string;
  supplierID: string;
  supplierIneligibleMessage?: string;
  expectedFiles: number;
  receivedFiles: number;
  processedFiles: number;
  status: boolean;
  pricingStatusColor: string;
  progressStatus: ProgressStatus;
  progressStatusText?: string;
}

export enum ProgressStatus {
  APPROVED = 'APPROVED',
  APPROVAL_PENDING = 'Processing',
  NOT_RECEIVED = 'NOT RECEIVED',
  INELIGIBLE = 'INELIGIBLE'
}



export interface FilterClass {
  label: string;
  value: string;
  disabled?: boolean;
  command?: any;
  subtext?: string;
}

export interface FilterClassWithId {
  label: string;
  value: string;
  id: string;
}

export interface FilterClassNumber {
  label: string;
  value: number;
  disabled?: boolean;
}

export interface FilterClassBoolean {
  label: string;
  value: boolean;
  disabled?: boolean;
  selected?: boolean;
}

export interface PriceEntity {
  id: string;
  file_upload_id: string;
  effectivePriceDate: Date;
  createdDate: Date;
  updateDate: Date;
  state: string;
  startDate: Date;
  kwhMin: number;
  kwhMax: number;
  kwhRate: number;
  kwhRateCentsCopy?: number;
  zone: string;
  rateClass: string;
  utility: string | number;
  utilityID?: number;
  green: boolean
  term: number;
  loadFactor: string;
  supplier: string;
  supplierID: string;
  billingOption: string;
  productDetail: string;
  finalizedPrice: number;
  currentRate: number;
  median: number;
  savings: number;
  savingsPct: number;
  savingsCurrentRate: number;
  savingsUtilityRate: number;
  kwhRateCents: string;
  supplierName: string;
  capAndTransPassthrough: boolean;
  marginCapInfo: string;
  greenType: GreenType;
  greenPercent: number;
  product: ProductEnum;
  source: string;
  fileUploadId?: string;
  /** Only for UI dev **/
  supplierLogoImgPath?: string;
  medianPercentage?: number;
  savingsCurrentRatePct?: number;
  savingsUtilityRatePct?: number;
  taxesIncluded: boolean;
  taxRate: number;
  supplierAlertShort?: string;
}

export interface PriceEntityZenTable extends PriceEntity {
  renewableIcon?: string;
  renewableIconCls?: string;
}

export enum ProductEnum {
  Fixed = 'Fixed', // capAndTransPassthrough -> false
  FixedPassthrough = 'Fixed w/ Passthrough' // capAndTransPassthrough -> true
}

export enum GreenType {
  solar = 'Solar',
  wind = 'Wind',
  hydro = 'Hydro',
  geothermal = 'Geothermal',
  hybrid = 'Hybrid',
  other = 'Other',
}

export enum EnergySourceType {
  // new ones
  renewable = 'Renewable',
  undefined = 'Undefined',
  standard_mix = 'Standard Mix',
  // supporting carbon offsets. this is a bit confusing but should work for now.
  carbon_offsets = 'Carbon Offsets'
}

export interface PriceResponseDvFilter {
  distinctProductDetails: FilterClass[];
  distinctTerm: FilterClassNumber[];
  distinctEnergySourceDetails: FilterClass[];
  distinctSuppliers: FilterClass[];
  distinctBillingOptions: FilterClass[];
  distinctMarginCap: FilterClassBoolean[];
}

export interface PriceResponseDvFilterCriteria {
  [name: string]: any[];
}

export interface Zone {
  id: number;
  name: string;
  state: string;
}

export interface ZoneDetailsMapList {
  [zipCode: string]: ZoneDetails[]
}

export interface ZoneDetails {
  id: number;
  zoneId: number;
  zipCode: string;
  name: string;
  state: string;
}

export interface UtilityRateScheduleResponse {
  rateSchedules: UtilityRateSchedule[];
}

export interface UtilityRateSchedule {
  id: number;
  utilityId: number;
  code: string;
  description: string;
  type: string;
}

export interface SupplierRateClassCoverageMap {
  [rateScheduleId: string]: {
    rateScheduleId: number;
    numOfSuppliers: number;
    supplierIds: string[];
  }
}

export interface SupplierRateClassCoverageMapResponse {
  supplierRateClassCoverageMap: SupplierRateClassCoverageMap
}
