import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {FormGroup, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import * as moment from 'moment/moment';
import {Customer} from '../../_zen-legacy-common/_models/customer';
import {AuthenticationService} from '../../_zen-legacy-common/zen-common-services/_services/authentication.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ZenDialogDataModel} from '../zen-dialog/zen-dialog.component';
import {RateCheckRequestService} from '../../_zen-legacy-common/zen-common-services/tili-services/services/rate-check-request.service';
import {CommodityType} from '../../_zen-legacy-common/_models/commodity';
import {ZenDateTimePickerComponent} from '../../_components/zen-date-time-picker/zen-date-time-picker.component';
import {ZenDateModeEnum} from '../../_enums/zen-date-mode.enum';
import {ItemListV4Model} from '../../_model/item-list-v4.model';
import {MatDatepicker} from '@angular/material/datepicker';
import {ZenIconsEnum} from '../../_enums/zen-icons.enum';
import {ZenRcReqRenewableOptionsEnum} from '../../../_modules/zen-rate-check-request-v2/_enums/rc-req-electricity.enum';
import {FilterClass} from '../../_zen-legacy-common/zen-common-services/tili-services/models/matrix-pricing';
import {FeeMask} from '../../_enums/zen-masks.enum';
import {FeeError} from '../../../_modules/zen-market-pulse/_services/matrix-pricing-helper.service';
import {dollarsToMils, milsToDollars} from '../../_zen-legacy-common/_utils/currency-utils';
import {debounceTime, take} from 'rxjs/operators';
import {ZenFeesEnum} from '../../_enums/zen-rates.enum';
import {RateCheckV4Service} from '../../_services/v4/rate-check-v4.service';
import {BrokerFeesCalculated} from '../../_zen-legacy-common/zen-common-services/tili-services/models/broker-fees';
import {ZenBillingMethodEnum, ZenNatGasPaymentTermEnum} from '../../../_modules/zen-rate-check-request-v2/_enums/rc-req-natural-gas.enum';
import {Moment} from 'moment';
import {ZenNumberFormat} from '../../_enums/zen-number-format.enum';
import {SupplierOptionModel} from '../../_model/eligible-supplier-response.model';
import {ZenRequestRefreshDialogModel} from '../../_model/zen-request-refresh-dialog.model';
import {ZenRequestRefreshData} from '../../_model/zen-request-refresh-data.model';
import {ZenInputChipListSelectionComponent} from '../../_components/zen-input-chip-list-selection/zen-input-chip-list-selection.component';
import {RateCheckFeeDetails} from '../../_model/rate-check-v4.model';
import {ZenBaseComponent} from '../../_components/zen-base/zen-base.component';
import {feeErrorCheck} from '../../_utils/zen-fee.util';
import {ZenUnitsEnum} from '../../_enums/zen-units.enum';

@Component({
  selector: 'app-zen-request-refresh-dialog',
  templateUrl: './zen-request-refresh-dialog.component.html',
  styleUrls: ['./zen-request-refresh-dialog.component.scss'],
})
export class ZenRequestRefreshDialogComponent extends ZenBaseComponent implements OnInit {
  @ViewChild(ZenInputChipListSelectionComponent) termComponent: ZenInputChipListSelectionComponent;
  CommodityType = CommodityType;
  ZenUnitsEnum = ZenUnitsEnum;
  ZenNatGasPaymentTermEnum = ZenNatGasPaymentTermEnum;
  ZenIconsEnum = ZenIconsEnum;
  FeeMask = FeeMask;
  ZenNumberFormat = ZenNumberFormat;
  CHAR_LIMIT = 1000;
  label = 'Request Due Date';
  mode = ZenDateModeEnum.DUE_DATE;
  form: UntypedFormGroup;
  customer: Customer;
  loading: boolean;
  dueDate: Date = moment().add(1, 'day').hour(14).minute(0).toDate();
  commodityType: CommodityType;
  dateTimePicker: ZenDateTimePickerComponent;
  contractTermsList: ItemListV4Model[] = [];
  minContractStart: Date = new Date();
  minContractDateString: string;
  isElectric: boolean;
  ZenIcons = ZenIconsEnum;
  minTerm = 1;
  originalContractStartDate: Date;

  // Renewable Variables
  renewableOptions: FilterClass[] = [
    {label: 'Included', value: ZenRcReqRenewableOptionsEnum.YES},
    {label: 'Excluded', value: ZenRcReqRenewableOptionsEnum.NO},
    {label: 'Compare', value: ZenRcReqRenewableOptionsEnum.SHOW_BOTH}
  ];
  // Billing Method Variables
  BILLING_METHOD_DEFAULT = '0';
  billingMethods: FilterClass[] = [
    {label: 'No Preference (Default)', value: this.BILLING_METHOD_DEFAULT},
    {label: 'Dual', value: ZenBillingMethodEnum.DUAL},
    {label: 'Consolidated', value: ZenBillingMethodEnum.CONSOLIDATED}
  ];
  suppliers: SupplierOptionModel[] = [];
  // Fee variables
  feeError: FeeError = {};
  brokerFeesCalculated: BrokerFeesCalculated;
  feeDetails = {
    totalFee: 0,
    brokerFee: 0,
    platformFee: 0,
    minFeeDollarsLimit: 0,
    maxFeeDollarsLimit: 0,
    hasFee: false
  };
  // Payment term options, needed only for Gas;
  paymentTermsOptions: FilterClass[] = [
    {label: ZenNatGasPaymentTermEnum.NET_15, value: ZenNatGasPaymentTermEnum.NET_15},
    {label: ZenNatGasPaymentTermEnum.NET_20, value: ZenNatGasPaymentTermEnum.NET_20},
    {label: ZenNatGasPaymentTermEnum.NET_30, value: ZenNatGasPaymentTermEnum.NET_30}
  ];


  constructor(@Inject(MAT_DIALOG_DATA) public config: ZenDialogDataModel,
              public dialogRef: MatDialogRef<ZenRequestRefreshDialogComponent>,
              private authSvc: AuthenticationService,
              public rateCheckRequestService: RateCheckRequestService,
              public rcV4Svc: RateCheckV4Service
  ) {
    super();
    this.dateTimePicker = new ZenDateTimePickerComponent(rateCheckRequestService, authSvc);
  }

  ngOnInit() {
    this.form = new FormGroup({
      comments: new UntypedFormControl('', [Validators.maxLength(this.CHAR_LIMIT)]),
      dueDateCtrl: new UntypedFormControl('', [Validators.required]),
      dueDateTimeCtrl: new UntypedFormControl('', [Validators.required]),
      startingTerms: new UntypedFormControl(null, [Validators.required]),
      contractStart: new UntypedFormControl(new Date()),
      suppliersControl:  new UntypedFormControl(),
      renewable: new UntypedFormControl(),
      totalFee: new UntypedFormControl(null),
      paymentTerms: new UntypedFormControl(),
      billingMethod: new UntypedFormControl()
      // ... add other form controls as needed ...
    });
    this.loading = true;
    this.isElectric = this.config.data.commodityType === CommodityType.Electricity;
    this.commodityType = this.config.data.commodityType;

    this.getRateCheckRefreshData();

    this.form.controls.contractStart.valueChanges.subscribe(newDateValue => {
      this.adjustMinTerm(newDateValue);
    });
  }

  adjustMinTerm(newDateValue: string) {
    const newDate = new Date(newDateValue);
    const originalDate = new Date(this.originalContractStartDate);
    this.originalContractStartDate = newDate; // Update 'original' date to track changes
    const monthDifference = this.monthDiff(originalDate, newDate);

    if (monthDifference !== 0) {
      // Adjust minTerm based on the difference in months
      this.minTerm -= monthDifference;

      // Update selected terms if shift changes the minTerm
      const termsToRemove = this.form.controls.startingTerms.value.filter((x: { value: number; }) => x.value < this.minTerm);
      termsToRemove.forEach(t => this.termComponent.removeTerm(t));

      this.loadTermOptions();
    }
  }

  monthDiff(dateFrom: Date, dateTo: Date): number {
    let months = (dateTo.getFullYear() - dateFrom.getFullYear()) * 12;
    months -= dateFrom.getMonth();
    months += dateTo.getMonth();
    return months; // Returns negative if dateTo is before dateFrom
  }
  setInitialTerms(incomingTerms: number[]) {
    // Then, load your term options as per your existing logic
    this.loadTermOptions();

    this.termComponent.addTerms(this.contractTermsList.filter(t => incomingTerms.includes(t.key)));
  }

  loadTermOptions() {
    const DEFAULT_MONTH_TERMS: number[] = [6, 12, 18, 24, 30, 36, 48, 60];
    this.contractTermsList = DEFAULT_MONTH_TERMS.map(i => ({value: i.toString(), key: i, disabled: i < this.minTerm}));

    for (let i = 1; i <= 72; i++) {
      if (!DEFAULT_MONTH_TERMS.includes(i)) {
        this.contractTermsList.push({value: i.toString(), key: i, disabled: i < this.minTerm});
      }
    }
  }

  submit() {
    if (this.form.controls.dueDateCtrl.valid && this.form.controls.dueDateTimeCtrl.valid && !this.feeError.hasError) {
      // Create nested refreshData object for end point
      const refreshData: ZenRequestRefreshData = {
        supplierIds: this.form.controls.suppliersControl.value,
        renewable: this.form.controls.renewable.value,
        billingMethod: this.form.controls.billingMethod.value,
        totalFee: parseFloat(this.form.controls.totalFee.value),
        brokerFee: this.feeDetails.brokerFee,
        platformFee: this.feeDetails.platformFee,
        terms: this.form.controls.startingTerms.value.map((term: ItemListV4Model) => term.key),
        startDate: this.form.controls.contractStart.value,
        paymentTerms: this.form.controls.paymentTerms.value,
        dueDate: this.dateTimePicker.getMergedDateTime(this.form.controls.dueDateCtrl.value, this.form.controls.dueDateTimeCtrl.value),
        comments: this.form.controls.comments.value
      }
      // Create response object for dialog close
      const response: ZenRequestRefreshDialogModel = {
          refresh: true,
          dueDate: this.dateTimePicker.getMergedDateTime(this.form.controls.dueDateCtrl.value, this.form.controls.dueDateTimeCtrl.value),
          comments: this.form.controls.comments.value,
          startDate: this.form.controls.contractStart.value,
          refreshData: refreshData
        };
      this.dialogRef.close(response);
    } else {
      this.form.controls.comments.markAsTouched();
      this.form.controls.dueDateCtrl.markAsTouched();
      this.form.controls.dueDateTimeCtrl.markAsTouched();
    }
  }

  setMonthAndYear(normalizedMonthAndYear: Moment, datepicker: MatDatepicker<Moment>) {
    const startOfMonth = moment(normalizedMonthAndYear).startOf('month').format('YYYY-MM-DD hh:mm');
    this.form.controls.contractStart.setValue(new Date(startOfMonth));
    datepicker.close();
  }

  getRateCheckRefreshData() {
    this.rcV4Svc.getRateCheckRefreshData(this.config.data.customerId, this.config.data.rateCheckId).subscribe(rateCheckRefreshData => {
      this.loading = false;
      // Using spread operator to combine arrays which ensures the type compatibility
      this.suppliers = rateCheckRefreshData.suppliers;
      // Update control
      this.form.controls.suppliersControl.setValue(this.suppliers.filter(s => s.selected).map(s => s.value));
      this.form.controls.totalFee.setValue(JSON.stringify(this.commodityType === CommodityType.Electricity ? dollarsToMils(rateCheckRefreshData.totalFee) : rateCheckRefreshData.totalFee), {emitEvent: false});
      this.recalculateFees();
      this.customer = rateCheckRefreshData.customer;
      this.originalContractStartDate = moment(rateCheckRefreshData.startDate).toDate();
      this.form.controls.contractStart.setValue( new Date(moment(rateCheckRefreshData.startDate).format('YYYY-MM-DD hh:mm')));
      this.minContractStart = new Date(moment(rateCheckRefreshData.minDate).format('YYYY-MM-DD hh:mm'));
      this.minContractDateString = moment(rateCheckRefreshData.minDate).format('YYYY-MM-DD');
      this.minTerm = rateCheckRefreshData.minTerm;
      this.form.controls.renewable.setValue(rateCheckRefreshData.renewableProduct);
      this.form.controls.billingMethod.setValue(rateCheckRefreshData.billingMethod != null ? rateCheckRefreshData.billingMethod : this.BILLING_METHOD_DEFAULT);
      this.form.controls.paymentTerms.setValue(rateCheckRefreshData.paymentTerm != null ? rateCheckRefreshData.paymentTerm : ZenNatGasPaymentTermEnum.NET_15);
      this.form.controls.comments.setValue(rateCheckRefreshData.comments);
      this.setInitialTerms(rateCheckRefreshData.terms);
    }, error => {

      this.loading = false;
      }
    );
  }
  recalculateFees(): void {
    let _totalFee = parseFloat(this.form.controls.totalFee.value);
    _totalFee = _totalFee ? _totalFee : null;

    if (this.isElectric && _totalFee) {
      _totalFee = parseFloat(milsToDollars(_totalFee).toFixed(5));
    }

    this.rcV4Svc.calculateRateCheckFees(this.config.data.customerId, this.config.data.rateCheckId, _totalFee)
      .pipe(take(1), debounceTime(200))
      .subscribe(feeDetails => {
        this.brokerFeesCalculated = feeDetails;
        this.feeDetails.totalFee = feeDetails.totalFee;
        this.feeDetails.brokerFee = feeDetails.brokerFee;
        this.feeDetails.platformFee = feeDetails.platformFee;
        this.feeDetails.minFeeDollarsLimit = (feeDetails.feeSource.platformFee) ? feeDetails.feeSource.platformFee : 0.0;
        this.feeDetails.maxFeeDollarsLimit = this.commodityType === CommodityType.Gas ? ZenFeesEnum.NAT_GAS_MAX_FEE : ZenFeesEnum.ELECTRICITY_MAX_FEE_MILLS;
        this.feeDetails.hasFee = true;

        // The electric fees are handled in mills
        if (this.isElectric) {
          this.feeDetails.totalFee = dollarsToMils(this.feeDetails.totalFee);
          this.feeDetails.brokerFee = dollarsToMils(this.feeDetails.brokerFee);
          this.feeDetails.platformFee = dollarsToMils(this.feeDetails.platformFee);
          this.feeDetails.minFeeDollarsLimit = dollarsToMils(this.feeDetails.minFeeDollarsLimit);
          this.feeDetails.maxFeeDollarsLimit = dollarsToMils(this.feeDetails.maxFeeDollarsLimit);

          this.form.controls.totalFee.setValue(JSON.stringify(dollarsToMils(feeDetails.totalFee)), {emitEvent: false});
        } else {
          this.form.controls.totalFee.setValue(JSON.stringify(feeDetails.totalFee), {emitEvent: false});
        }
        this.feeError = feeErrorCheck(this.feeDetails);
      });
  }

  updateFee(feeDet: RateCheckFeeDetails) {
    this.form.controls.totalFee.setValue(JSON.stringify(feeDet.totalFee), {emitEvent: false});
  }


}

