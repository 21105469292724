import { Pipe, PipeTransform } from '@angular/core';
import {CurrencyPipe, DecimalPipe} from '@angular/common';
import {UnitDto} from '../_model/unit-model';
import {ZenCurrencyTypeEnum} from '../_enums/zen-currency-type.enum';
import {TranslateService} from '@ngx-translate/core';
import {ZenLocaleModel} from '../_model/zen-locale.model';

@Pipe({
  name: 'unitFormat'
})
export class UnitFormatPipe implements PipeTransform {

  constructor(private decimalPipe: DecimalPipe, private currencyPipe: CurrencyPipe, private translateSvc: TranslateService) {}

  transform(value: number, args: {unitDto: UnitDto, currencyType?: ZenCurrencyTypeEnum}): string {

    // Set value to 0 if it is null, undefined, or NaN
    if (value === undefined || value === null || isNaN(value) || args.unitDto == null) {
      value = 0;
    }
    // Create the format string dynamically
    const format = `1.2-${args.unitDto.decimalPlaces}`;


    if (args.currencyType) {
      const _translations = Object.values(this.translateSvc.translations)?.[0] as ZenLocaleModel;
      return this.currencyPipe.transform(value, _translations?.currency?.abbreviation, 'symbol-narrow', format);
    }
    // Use DecimalPipe to format the value
    return this.decimalPipe.transform(value, format);
  }

}
