import { Injectable } from '@angular/core';
import {environment} from '../../../../../../environments';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {OrganizationManagementService} from '../../_zen-legacy-common/zen-common-services/_services/organization-management.service';
import {Observable} from 'rxjs';
import {take} from 'rxjs/operators';
import {BillOverlapResponse, BillsV4ListContentModel, BillV4CreateRequest, BillV4UpdateRequest} from '../../_model/bills-v4.model';
import {PageableModel} from '../../../_modules/portfolio/_model/pageable.model';
import {getParams} from '../../_zen-legacy-common/_utils/set-param-util';
import moment from 'moment';
import {DownloadService} from '../../_zen-legacy-common/zen-common-services/_services/download.service';
import {UnitDto} from '../../_model/unit-model';

@Injectable({
  providedIn: 'root'
})
export class BillsV4Service {
  baseUrl = environment.apiBaseUrl;

  constructor(private http: HttpClient, private orgMgtSvc: OrganizationManagementService,
              private downloadService: DownloadService) { }

  getBillsByMeterId(customerId: number, serviceAddressId: number, meterId: number, size: number, sort = 'billPeriodEnd,desc'): Observable<PageableModel<BillsV4ListContentModel>> {
    let httpParams = getParams({size, sort});
    const url = `${this.baseUrl}/v4/organizations/${this.orgMgtSvc.getOrganizationId()}/customers/${customerId}/service-addresses/${serviceAddressId}/meters/${meterId}/bills`;
    return this.http.get<PageableModel<BillsV4ListContentModel>>(url, {params: httpParams}).pipe(take(1));
  }


  createBills(customerId: number, serviceAddressId: number, meterId: number, request: BillV4CreateRequest): Observable<BillsV4ListContentModel> {
    const url = `${this.baseUrl}/v4/organizations/${this.orgMgtSvc.getOrganizationId()}/customers/${customerId}/service-addresses/${serviceAddressId}/meters/${meterId}/bills`;
    return this.http.post<BillsV4ListContentModel>(url, request).pipe(take(1));
  }

  updateBills(customerId: number, serviceAddressId: number, meterId: number, request: BillV4UpdateRequest): Observable<BillsV4ListContentModel> {
    const url = `${this.baseUrl}/v4/organizations/${this.orgMgtSvc.getOrganizationId()}/customers/${customerId}/service-addresses/${serviceAddressId}/meters/${meterId}/bills/${request.utilityBillId}`;
    return this.http.put<BillsV4ListContentModel>(url, request).pipe(take(1));
  }

  updateBillFile(customerId: number, serviceAddressId: number, meterId: number, billId: number,  tempFileId: string): Observable<BillsV4ListContentModel> {
    const url = `${this.baseUrl}/v4/organizations/${this.orgMgtSvc.getOrganizationId()}/customers/${customerId}/service-addresses/${serviceAddressId}/meters/${meterId}/bills/${billId}/file`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http.put<BillsV4ListContentModel>(url, JSON.stringify(tempFileId), httpOptions).pipe(take(1));
  }

  deleteBills(customerId: number, serviceAddressId: number, meterId: number, utilityBillId: number): Observable<any> {
    const url = `${this.baseUrl}/v4/organizations/${this.orgMgtSvc.getOrganizationId()}/customers/${customerId}/service-addresses/${serviceAddressId}/meters/${meterId}/bills/${utilityBillId}`;
    return this.http.delete(url).pipe(take(1));
  }
  bulkDeleteBills(customerId: number, serviceAddressId: number, meterId: number, utilityBillIds: number[]): Observable<any> {
    const url = `${this.baseUrl}/v4/organizations/${this.orgMgtSvc.getOrganizationId()}/customers/${customerId}/service-addresses/${serviceAddressId}/meters/${meterId}/bills`;
    return this.http.delete(url, {body: utilityBillIds}).pipe(take(1));
  }
  /**
   * The below used to check overlapping bill dates.
   * @param billStartDate - API format - 'YYYY-MM-DD'
   * @param billEndDate - API format - 'YYYY-MM-DD'
   */
  validateBillDates(customerId: number, serviceAddressId: number, meterId: number, billStartDate: moment.Moment, billEndDate: moment.Moment, billId?: number): Observable<BillOverlapResponse> {
    let httpParams = getParams({billStartDate: moment(billStartDate).format('YYYY-MM-DD'), billEndDate: moment(billEndDate).format('YYYY-MM-DD'), billId});
    const url = `${this.baseUrl}/v4/organizations/${this.orgMgtSvc.getOrganizationId()}/customers/${customerId}/service-addresses/${serviceAddressId}/meters/${meterId}/bill-dates`;
    return this.http.get<BillOverlapResponse>(url, {params: httpParams}).pipe(take(1));
  }

  /**
   * TODO: This is a v1 svc used to download upload bill files. In future we need to update this one with v4.
   */
  downloadBill(customerId: number, meterId: number, billId: number) {
    const url = `${environment.apiBaseUrl}/v1/customers/${customerId}/service-addresses/${meterId}/bills/${billId}/file`;
    this.downloadService.downloadFile(url, `bill_${billId}.pdf`);
  }
}
