import {map, take} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {environment} from '../../../../../../../../environments';
import {AuthenticationService} from '../authentication.service';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {RateCheckWebReportMeter} from '../../../_models/rate-checks/v2/rate-check-web-report';
import {GenericResponseList} from '../../../_models/generic-response';
import { MatrixRateCheck }
  from '../../../../../_modules/zen-reports/_modules/zen-rate-check-report/_models/matrix-rate-check.interface';
import { RateCheckReportsModel } from '../../../../../_modules/zen-rate-checks/_model/reports/rate-check-reports.model';
import {getParams} from '../../../_utils/set-param-util';

@Injectable()
export class RateCheckWebReportService {

  private baseUrl = environment.apiBaseUrl;

  constructor(
    private http: HttpClient,
    private authService: AuthenticationService) {}

  public generateMatrixRateCheckReport(data: MatrixRateCheck, customerId: number): Observable<GenericResponseList<RateCheckWebReportMeter>> {
    const url = `${this.baseUrl}/v2/organization/${this.authService.getOrganizationId()}/customer/${customerId}/matrix_rate_check/generate`;
    return this.http.post<GenericResponseList<RateCheckWebReportMeter>>(url, data).pipe(take(1));
  }

  public addPricingSolutionToWebReports(customerId: number, rateCheckId: string,  pricingSolutionId: string, recommended?: boolean): Observable<RateCheckReportsModel> {
    const params = getParams({recommended});
    const url = `${this.baseUrl}/v2/app/customer/${customerId}/rate_check_web_report/rate_check_id/${rateCheckId}/add_solution/${pricingSolutionId}`;
    return this.http.put<RateCheckReportsModel>(url, {}, {params}).pipe(take(1));
  }

  public removePricingSolutionFromWebReports(customerId: number, rateCheckId: string,  pricingSolutionId: string): Observable<RateCheckReportsModel> {
    const url = `${this.baseUrl}/v2/app/customer/${customerId}/rate_check_web_report/rate_check_id/${rateCheckId}/remove_solution/${pricingSolutionId}`;
    return this.http.delete<RateCheckReportsModel>(url).pipe(take(1));
  }
}
