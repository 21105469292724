import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ButtonActionTypes, ZenDialogActionButton, ZenDialogDataModel} from '../zen-dialog/zen-dialog.component';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {zenHasError} from '../../_utils/zen-has-error.util';
import {UndefinedMetersToUtilityDto} from '../../_model/meter-energy-plan.model';
import {OrganizationManagementService} from '../../_zen-legacy-common/zen-common-services/_services/organization-management.service';
import {CommodityType} from '../../_zen-legacy-common/_models/commodity';
import {PfMetersTableRowModel} from '../../../_modules/portfolio/_model/portfolio-meters.model';
import {ZenUnitsHelperService} from '../../_services/helpers/zen-units-helper.service';
import {ZenBaseComponent} from '../../_components/zen-base/zen-base.component';
import {ZenUnitsEnum} from '../../_enums/zen-units.enum';
import {ZenRatesEnum} from '../../_enums/zen-rates.enum';
import {UnitService} from '../../_services/v4/unit-service';
import {UnitDto} from '../../_model/unit-model';

@Component({
  selector: 'app-zen-bulk-assign-meters-energy-plan-dialog',
  templateUrl: './zen-bulk-assign-meters-energy-plan-dialog.component.html',
  styleUrls: ['./zen-bulk-assign-meters-energy-plan-dialog.component.scss']
})
export class ZenBulkAssignMetersEnergyPlanDialogComponent extends ZenBaseComponent implements OnInit {
  form: FormGroup;
  meterInfo: PfMetersTableRowModel;
  CommodityType = CommodityType;
  loading: boolean;
  unit = new FormControl(null, [Validators.required]);
  rateValidationMsg: string;
  allUnits: UnitDto[];
  constructor(public dialogRef: MatDialogRef<ZenBulkAssignMetersEnergyPlanDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: ZenDialogDataModel,
              private fb: FormBuilder,
              private orgMgtSvc: OrganizationManagementService,
              private zenUnitsSvc: ZenUnitsHelperService,
              private unitService: UnitService
  ) {
    super();
  }

  ngOnInit(): void {
    this.meterInfo = this.data.data?.metersSelected[0];
    this.unitService.getUnits().subscribe(units => {
    this.allUnits = units;

      if (this.meterInfo?.commodityType) {
        this.buildForm();
        this.setUnitOptions();
        setTimeout(() => this.setValidation());

        this.unit.valueChanges.subscribe(() => this.setValidation());
      }
  })
  }

  setUnitOptions() {
    if (this.meterInfo?.commodityType === CommodityType.Electricity) {
      this.unit.setValue(ZenUnitsEnum.kWh);
    } else {
      this.unit.setValue(ZenUnitsEnum.Dth);
    }
    this.rateValidationMsg = this.zenUnitsSvc.getRateFieldValidationMsg(this.meterInfo?.commodityType, this.unit.value, this.allUnits);
  }

  buildForm() {
    this.form = this.fb.group({
      supplierRate: [null]
    });
  }

  setValidation() {
    this.rateValidationMsg = this.zenUnitsSvc.getRateFieldValidationMsg(this.meterInfo?.commodityType, this.unit.value, this.allUnits);

    const {min, max} = this.zenUnitsSvc.getMinAndMaxValidationValue(this.meterInfo?.commodityType, this.unit.value, this.allUnits);

    this.form.controls.supplierRate.setValidators([Validators.min(min), Validators.max(max)]);
    this.form.controls.supplierRate.updateValueAndValidity();
  }

  submit(btn: ZenDialogActionButton) {
    if (btn.actionType === ButtonActionTypes.CANCEL) {
      this.dialogRef.close(false);
    } else {
      this.form.markAllAsTouched();
      if (this.form.valid) {
        btn.command(
          {
            ...this.form.value,
            supplierRate: this.zenUnitsSvc.applyToPlatformMultiplier(this.unit.value, parseFloat(this.form.value.supplierRate), this.allUnits)
          } as Partial<UndefinedMetersToUtilityDto>
        );
      }
    }
  }

  hasError(formControlName: string, formErrorName: string): boolean {
    return zenHasError(this.form, formControlName, formErrorName);
  }
}
