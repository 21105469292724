<div class="zen-unit-conversion-input-main d-flex align-items-center position-relative" *ngIf="mask">
  <mat-form-field appearance="outline" class="left-input">
    <mat-label>{{label}}</mat-label>
    <input matInput [imask]="mask" [unmask]="true" [formControl]="formCtrl">
  </mat-form-field>
  <mat-form-field appearance="outline" class="right-input">
    <mat-select [formControl]="selectedConversionUnit" (selectionChange)="handleUnitChange($event.value)">
      <mat-option *ngFor="let unit of unitOptions" [value]="unit.value">
        {{ unit.label }}
      </mat-option>
    </mat-select><i class="material-symbols-rounded" matSuffix>expand_more</i>
  </mat-form-field>
</div>
