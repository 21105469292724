import {EventEmitter, Injectable} from '@angular/core';
import {
  ZenTableColsModel,
  ZenTableConfig,
  ZenTableMenuOption
} from '../../../../_shared/_components/zen-mat-table/zen-mat-table.component';
import {
  ZenContractV4ListModel,
  ZenProcurementSnapshotTblRowModel
} from '../../../../_shared/_model/contract-v4.model';
import {DecimalPipe} from '@angular/common';
import {CommodityType} from '../../../../_shared/_zen-legacy-common/_models/commodity';
import {ZenPageEnum} from '../../../../_shared/_enums/zen-page.enum';
import {ZenErrorMsgEnum} from '../../../../_shared/_enums/zen-error-msg.enum';
import {ContractsV4Service} from '../../../../_shared/_services/v4/contracts-v4.service';
import {PortfolioHelperService} from './portfolio-helper.service';
import {ZenDialogMsgService} from '../../../../_shared/_services/zen-dialog-msg.service';
import {ZenContractListHelperService} from '../../../zen-contracts/_services/zen-contract-list-helper.service';
import {UntypedFormControl} from '@angular/forms';
import {PortfolioMetersHelperService} from './portfolio-meters-helper.service';
import {FormTypesEnum} from '../../../../_shared/_zen-legacy-common/_enums/form-types.enum';
import {
  ButtonTypes,
  ZenDialogComponent,
  ZenDialogDataModel,
  ZenDialogDataType
} from '../../../../_shared/_dialogs/zen-dialog/zen-dialog.component';
import {MeterDetailsV4Model, TimelineStatus, EnergyPlanTimeline} from '../../_model/portfolio-meters.model';
import {PortfolioTabTopActionConfig} from '../../../../_shared/_components/zen-tab-top-action/zen-tab-top-action.component';
import {ZenDialogSizeEnum} from '../../../../_shared/_enums/zen-dialogs.enum';
import {ContractFriendlyStatus} from '../../../../_shared/_zen-legacy-common/_utils/contract-utils';
import {NgxPopperjsPlacements} from 'ngx-popperjs';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {MeterUsageProfileV4Service} from '../../../../_shared/_services/v4/meter-usage-profile-v4.service';
import {ContractSource} from '../../../../_shared/_enums/zen-contract.enum';
import {ZenIconsEnum} from '../../../../_shared/_enums/zen-icons.enum';
import {RateCheckStatusEnum} from '../../../../_shared/_zen-legacy-common/_models/rate-checks/rate-check-status';
import {
  ZenAddProcurementSnapshotDialogComponent
} from '../../../../_shared/_dialogs/zen-add-procurement-snapshot-dialog/zen-add-procurement-snapshot-dialog.component';
import {PortfolioFilterService} from './portfolio-filter.service';
import {capitalizeEachFirstLetter} from '../../../../_shared/_zen-legacy-common/_utils/format-utils';
import {getContractStatusCls} from '../../../../_shared/_utils/zen-contract.util';

@Injectable({
  providedIn: 'root'
})
export class PortfolioProcurementSnapshotHelperService {
  psTableConfig: ZenTableConfig;
  tableTopConfig: PortfolioTabTopActionConfig = {
    isDialog: true,
    actionBtn: {label: 'Contract', icon: 'add', command: () => this.handleAddContract()}
  };
  linkedContractTableTopConfig: PortfolioTabTopActionConfig = {
    isDialog: true,
    actionBtn: {label: 'Reset', btnType: ButtonTypes.MAT_BUTTON, command: () => {
      this.assignToUtility.setValue(false);
      this.loadData(true)
    }}
  };
  rowMenuOptions: ZenTableMenuOption[] = [];
  loading: boolean;
  eligibleContracts: ZenProcurementSnapshotTblRowModel[] = [];
  linkedContracts: ZenProcurementSnapshotTblRowModel[] = [];
  lockedCurrentContractWithRcId: ZenProcurementSnapshotTblRowModel;
  disableAssignToUtility: boolean;
  // On Utility checkbox
  assignToUtility = new UntypedFormControl();
  excludeFromOpportunities = new UntypedFormControl();
  excludeUntilDate = new UntypedFormControl();
  contractDataLoaded = new EventEmitter<void>();

  // Add/Edit contract form
  contractFormType: FormTypesEnum;
  contractFormData: Partial<ZenDialogDataModel>;
  meterDet: MeterDetailsV4Model;

  // Handle dialog - Add contract form changes
  handleDialogWidthChanges = new EventEmitter<ZenDialogSizeEnum>();

  // meterIsOnEP flag is not a stable one which changes while swapping.
  // So, until doing the final save we need to know the initial state to perform swaping.
  initialEpIds = {linked: [], eligible: []};

  simulatedTimelineData: EnergyPlanTimeline[] = [];


  constructor(private decimalPipe: DecimalPipe, private contractsV4Svc: ContractsV4Service,
              private zenDialogSvc: ZenDialogMsgService, private pfHelpSvc: PortfolioHelperService,
              private pfHelpFilterSvc: PortfolioFilterService,
              private dialog: MatDialog,
              public contractHelpSvc: ZenContractListHelperService, public pfMeterHelpSvc: PortfolioMetersHelperService,
              private mtrUsgProfSvc: MeterUsageProfileV4Service) {
  }

  initTable(commodityType: CommodityType, meterDet: MeterDetailsV4Model, dialogRef: MatDialogRef<ZenAddProcurementSnapshotDialogComponent>) {
    this.meterDet = meterDet;
    this.tableTopConfig.actionBtn = {label: 'Contract', icon: 'add', command: () => this.handleAddContract(meterDet, dialogRef)};

    const _tblCols: ZenTableColsModel[] = [
      {
        field: 'contractStatus', header: 'Contract Status', statusCol: 'statusCls',
        iconCol: 'bookedInOurSystemIcon', iconClsCol: 'bookedInOurSystemCls', iconPosition: 'order-last',
        subTextCol: 'supplierName', type: 'standard'
      },
      {
        field: 'contractStrategyProcurement', header: 'Contract Strategy', iconCol: 'contractStrategyIcon',
        iconClsCol: 'contractStrategyCls', subTextCol: 'contractConfiguration', type: 'standard',
        hyperlink: true, handleHyperLinkClick: (col, rowData) => {
          this.dialog.closeAll(); // to close procurement snapshot dialog.
          this.contractHelpSvc.handleViewContract(rowData);
        }
      },
      {field: 'startDateText', header: 'Date', type: 'standard', hidden: true}, // To support client side search
      {field: 'endDateText', header: 'Date', type: 'standard', hidden: true}, // To support client side search
      {
        field: 'planStartDate', header: 'Date', type: 'standard',
        formatter: (col, rowData) => rowData.planStartDate == null ? '-' : `${this.contractHelpSvc.dateFormatter(rowData.planStartDate)} -
           ${this.contractHelpSvc.dateFormatter(rowData.planEndDate)}`,
        subTextCol: 'termMonthsText'
      }
    ];

    this.psTableConfig = {
      rowMenuOptions: this.rowMenuOptions,
      searchPlaceholder: 'Search Contracts',
      cols: _tblCols,
      rowTooltip: {enable: true, popperPlacement: NgxPopperjsPlacements.AUTOSTART}
    };
  }

  /**
   * Loading list of EPs for Add procurement snapshot modal w.r.t meterId.
   * Using below API to visualize how the linked and eligible contracts looks like using - addEpIds & removeEpIds
   */
  loadData(initialLoad: boolean, addEpIds: number[] = [], removeEpIds: number[] = [], page = ZenPageEnum.DEFAULT_PAGE, size = ZenPageEnum.MAX_SIZE) {
    this.loading = initialLoad;
    const {customerId, serviceAddressId, meterId} = this.pfHelpFilterSvc.hierarchyIds;
    this.eligibleContracts = [];
    this.linkedContracts = [];
    this.contractsV4Svc.getCustomerMeterContracts(customerId, serviceAddressId, meterId, addEpIds, removeEpIds, page, size)
      .subscribe(contracts => {
        this.loading = false;
        this.eligibleContracts = [...contracts.content].filter(c => !this.isLinkedContractByDefault(c))
          .map(c => this.getRowModified(c));
        this.linkedContracts = [...contracts.content].filter(c => this.isLinkedContractByDefault(c))
          .map(c => this.getRowModified(c));

        // Disable assignToUtility checkbox when - a current contract is selected + booked inside the platform
        // If that current contract was done outside the system it can be deselected, at which point this option becomes enabled again.
        this.lockedCurrentContractWithRcId = this.eligibleContracts.find(c => c.rateCheckId &&
          c.contractStatus === ContractFriendlyStatus.Current);
        this.disableAssignToUtility = Boolean(this.lockedCurrentContractWithRcId);

        // meterIsOnEP flag is not a stable one which changes while swapping.
        // So, until doing the final save we need to know the initial state to perform swaping.
        if (initialLoad) {
          this.initialEpIds = {
            linked: this.linkedContracts.map(c => c.energyPlanId),
            eligible: this.eligibleContracts.map(c => c.energyPlanId)
          }
        }

        // setting default state
        if (this.linkedContracts?.length === 0 && this.eligibleContracts?.length === 0) {
          this.psTableConfig.defaultState = {
            title: 'Pro Tip!',
            message: `Add a contract in order to define a procurement snapshot for this meter.`,
            actions: {
              isDefaultState: true,
              actionBtn: this.tableTopConfig.actionBtn
            }
          }
        } else {
          this.psTableConfig.defaultState = null;
        }

        this.updateTableLength();
        // Update simulated timeline
        this.mtrUsgProfSvc.getSimulatedMeterTimelines(customerId, serviceAddressId, meterId, addEpIds, removeEpIds).subscribe(timelines => {
          this.simulatedTimelineData = timelines;
          this.contractDataLoaded.emit();
        }, e => {
          this.zenDialogSvc.openErrorDialog(true, e.error?.message || ZenErrorMsgEnum.ERR_MSG_1_TEXT);
          this.zenDialogSvc.openToast(false);
          this.loading = false;
        });
      }, e => {
        this.zenDialogSvc.openErrorDialog(true, e.error?.message || ZenErrorMsgEnum.ERR_MSG_1_TEXT);
        this.zenDialogSvc.openToast(false);
        this.loading = false;
      });
  }

  /** Used group by linked contracts. */
  isLinkedContractByDefault = (c: ZenContractV4ListModel) => c.meterIsOnEP;

  getRowModified(c: ZenContractV4ListModel): ZenProcurementSnapshotTblRowModel {
    let contractStrategyCls;
    // @ts-ignore
    if (c.commodityType === CommodityType.Electricity && c.productModelConfiguration?.configuration?.source !== ContractSource.ELECTRIC_STANDARD_MIX) {
      contractStrategyCls = 'green';
    } else if (c.commodityType === CommodityType.Gas && c.greenPercent > 0) {
      contractStrategyCls = 'green';
    }

    return {
      energyPlanId: c.energyPlanId,
      rateCheckId: c.rateCheckId,
      contractStatus: c.contractStatus,
      supplierName: c.supplierName,
      planStartDate: c.planStartDate,
      planEndDate: c.planEndDate,
      supplierRate: c.supplierRate,
      termMonthsText: c.termMonths != null && c.termMonths > 0 ?  c.termMonths + ' Months' : '',
      statusCls: getContractStatusCls(c.contractStatus),
      contractStrategyProcurement: this.contractHelpSvc.getContractStrategyProcurementText(c), // Used in procurement snapshot dialog
      contractConfiguration: c.productModelConfiguration?.productModel?.name || '-',
      contractStrategyIcon: ZenIconsEnum.GREEN, contractStrategyCls,
      bookedInOurSystemIcon: (c.rateCheckId && c.rateCheckStatusCode === RateCheckStatusEnum.COMPLETE ? ZenIconsEnum.VERIFIED : null),
      bookedInOurSystemCls: 'material-symbols-rounded no-bg',
      startDateText: c.planStartDate == null ? '-' : this.contractHelpSvc.dateFormatter(c.planStartDate), // To support client side search
      endDateText: c.planEndDate == null ? '-' :  this.contractHelpSvc.dateFormatter(c.planEndDate), // To support client side search
      rateTypeText: capitalizeEachFirstLetter(c.rateType),
      unit: c.unit,
      // Linked contracts -> Lock condition
      lockedContract: Boolean(c.rateCheckId && c.rateCheckStatusCode === RateCheckStatusEnum.COMPLETE)
    } as ZenProcurementSnapshotTblRowModel;
  }

  /**
   * This method handles save from the procurement snapshot dialog.
   * The important params to note in the API call -> energyPlanIds: number[] and assignToUtility
   */
  updateProcurementSnapshot(dialogRef, isCreatingNewContract?: boolean) {
    this.loading = true;
    const {customerId, serviceAddressId, meterId} = this.pfHelpFilterSvc.hierarchyIds;
    const addingDraftContract = this.linkedContracts.filter(c => c.contractStatus === ContractFriendlyStatus.Draft).length > 0;
    const addEpIds = this.linkedContracts.filter(c =>  !this.initialEpIds?.linked?.includes(c.energyPlanId)).map(c => c.energyPlanId) || [];
    const removeEpIds = this.eligibleContracts.filter(c => this.initialEpIds?.linked?.includes(c.energyPlanId)).map(c => c.energyPlanId) || [];

    // If on utility got unchecked, it should be in the removeEpIds array.
    if (this.assignToUtility.dirty && !this.assignToUtility.value &&
      this.pfMeterHelpSvc.timelineData[0] && this.pfMeterHelpSvc.timelineData[0].timelineStatus === TimelineStatus.ON_UTILITY) {
      removeEpIds.push(this.pfMeterHelpSvc.timelineData[0].energyPlanId);
    }

    // If adding draft contract, remove all other linked contracts
    if (addingDraftContract) {
      const draftContractId = this.linkedContracts.find(c => c.contractStatus === ContractFriendlyStatus.Draft).energyPlanId;
      this.initialEpIds?.linked.filter(c => c !== draftContractId).forEach(c =>
        removeEpIds.push(c));
    }

    const firstSegment = this.pfMeterHelpSvc.timelineData[0]
    const isAssignUtilityChanged = firstSegment && ((this.assignToUtility.value && firstSegment.timelineStatus !== TimelineStatus.ON_UTILITY)
    || (!this.assignToUtility.value && firstSegment.timelineStatus === TimelineStatus.ON_UTILITY));
    // Submit form only if changes made
    if (addEpIds.length || removeEpIds.length || isAssignUtilityChanged) {
      this.contractsV4Svc.updateProcurementSnapshotContract(customerId, serviceAddressId, meterId,
        addEpIds, removeEpIds, this.assignToUtility.value)
        .subscribe(() => {
          this.loading = false;
          this.pfMeterHelpSvc.initUsageProfile(customerId, serviceAddressId, meterId);
          this.zenDialogSvc.openToast(true);

          if (isCreatingNewContract) {
            addEpIds.forEach(ep => {this.initialEpIds.linked.push(ep)}); // Add saved EPs
            this.initialEpIds.linked = this.initialEpIds.linked.filter(e => !removeEpIds.includes(e)); // Remove removed EPs
            this.handleAddContract(this.meterDet, null, true); // Navigate to add contract screen
          } else {
            dialogRef?.close();
          }
        }, e => {
          this.showOverlapErrorOrGeneric(e.error?.message);
          this.zenDialogSvc.openToast(false);
          this.loading = false;
        });
    } else {
        dialogRef?.close();
    }
  }

  // This handles some edge cases around showing error messages for specific scenarios
  showOverlapErrorOrGeneric(errorResponse: string) {
    // Check for specific JSON response error
    if (errorResponse?.includes('contractDetails')) {
      let jsonError = JSON.parse(errorResponse);
      let errorMessage = `${jsonError.errorMessage}<br><ul>`; // wrap after overlap, start list

      let existingContractDetailError = `of <ul>`;
      if (errorResponse.includes('existingContractDetails')) {
        jsonError.existingContractDetails.forEach(x => {
          existingContractDetailError += `<li>${x}`; // add bullet points
        });
        errorMessage = errorMessage.replace('of {}', existingContractDetailError + `</ul>`); // close list
      }

      jsonError.contractDetails.forEach(x => {
        errorMessage += `<li>${x}`; // add bullet points
      })
      errorMessage += `</ul>`;  // close list
      this.zenDialogSvc.openErrorGuidance(null, errorMessage);
    } else {
      this.zenDialogSvc.openErrorGuidance(null, ZenErrorMsgEnum.ERR_MSG_1_TEXT);
    }
  }
  /**
   * Handling add new contract after submit scenarios here.
   */
  handleContractFormAfterSubmit(contract: ZenContractV4ListModel) {
    const {customerId, serviceAddressId, meterId} = this.pfHelpFilterSvc.hierarchyIds;
    this.pfMeterHelpSvc.initUsageProfile(customerId, serviceAddressId, meterId); // reload usage profile
    // Reloading contract list + adding newly created EP into linked contracts
    this.loadData(true, [contract.energyPlanId]);
    this.closeContractForm(); // close contract form
  }

  /**
   * Handling add new contract form changes inside the dialog. On clicking this
   * 1) Add new contract form will show up.
   * 2) Contract list tab will get hidden.
   */
  handleAddContract(meterDet?: MeterDetailsV4Model, prodDialogRef?: MatDialogRef<ZenAddProcurementSnapshotDialogComponent>, isAfterSave?: boolean) {
    const addEpIds = this.getAddEpIds();
    const removeEpIds = this.getRemoveEpIds();
    if (((addEpIds != null && addEpIds.length > 0) || (removeEpIds != null && removeEpIds.length > 0)) && !isAfterSave) {
      // show confirm save pop-up
      const dialogData: ZenDialogDataModel = {
        type: ZenDialogDataType.CONFIRM,
        bodyHtml: `<div>To create a new contract you need to first save your current changes.</div>`,
        header: {title: 'Portfolio Management'},
        onClose: () => dialogRef.close(),
        actionButtons: [
          {label: 'Cancel', btnType: ButtonTypes.MAT_BUTTON, command: () => dialogRef.close()},
          {
            label: 'Save', btnType: ButtonTypes.MAT_RAISED_BUTTON, color: 'primary',
            command: () => {
              dialogRef.close();
              this.updateProcurementSnapshot(prodDialogRef, true); // Save changes
            }
          }
        ]
      };
      const dialogRef = this.dialog.open(ZenDialogComponent, {
        width: ZenDialogSizeEnum.SMALL,
        data: dialogData, autoFocus: false
      });
    } else {
      this.contractFormType = FormTypesEnum.ADD;
      this.contractFormData = {
        actionButtons: [
          {label: 'Cancel', command: () => this.closeContractForm()},
          {
            label: 'Save', btnType: ButtonTypes.MAT_RAISED_BUTTON, color: 'primary',
            command: () => this.closeContractForm()
          }
        ],
        data: {
          lockedCustomerId: this.meterDet.customerId,
          lockedCommodityType: this.meterDet.commodityType,
          lockedState: this.meterDet.serviceAddressState,
          meterId: meterDet.id,
          serviceAddressId: meterDet.serviceAddressId
        },
        onClose: () => this.closeContractForm(),
        type: ZenDialogDataType.ADD_DIALOG
      };
      this.handleDialogWidthChanges.emit(ZenDialogSizeEnum.LARGE);
    }
  }

  closeContractForm() {
    this.loadData(true);
    this.contractFormType = null;
    this.handleDialogWidthChanges.emit(ZenDialogSizeEnum.EXTRA_LARGE);
  }

  /* Moving Eligible contracts -> Linked contracts */
  handleLinkingEligibleContracts(rowData: ZenProcurementSnapshotTblRowModel) {
    const i = this.eligibleContracts.findIndex(c => c.energyPlanId === rowData.energyPlanId);
    if (i !== -1) {
      this.eligibleContracts.splice(i, 1);
      this.linkedContracts.push(rowData);
      this.updateTableLength();
    }
    this.handleSwapping();
  }

  /* Moving Linked contracts -> Eligible contracts */
  cancelLinking(rowData: ZenProcurementSnapshotTblRowModel) {
    const i = this.linkedContracts.findIndex(c => c.energyPlanId === rowData.energyPlanId);
    if (i !== -1) {
      this.linkedContracts.splice(i, 1);
      this.eligibleContracts.push(rowData);
      this.updateTableLength();
    }
    this.handleSwapping();
  }

  handleSwapping() {
    setTimeout(() => {
      this.loading = true;
      this.loadData(false, this.getAddEpIds(), this.getRemoveEpIds());
    }, 50);
  }

  getAddEpIds() {
    return this.linkedContracts
      .filter(c => !this.initialEpIds?.linked?.includes(c.energyPlanId))
      .map(c => c.energyPlanId);
  }

  getRemoveEpIds() {
    return this.eligibleContracts.filter(c => this.initialEpIds?.linked?.includes(c.energyPlanId))
      .map(c => c.energyPlanId);
  }

  updateTableLength() {
    this.psTableConfig.totalLength = this.eligibleContracts.length; // Setting material table(zen-table) total length
  }
}
