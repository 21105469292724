import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {MatFormFieldAppearance} from '@angular/material/form-field';
import {zenHasError} from '../../_utils/zen-has-error.util';
import {AbstractControl, UntypedFormGroup} from '@angular/forms';
import {ZenFormTypesEnum} from '../../_enums/zen-form-types.enum';
import {ZenMessageType} from '../zen-message/zen-message.component';
import {NoSpacesMask} from '../../_enums/zen-masks.enum';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {SignupService} from '../../_zen-legacy-common/zen-common-services/tili-services/services/signup.service';
import {AuthenticationService} from '../../_zen-legacy-common/zen-common-services/_services/authentication.service';
import {Subscription} from 'rxjs';
import {ZenBaseWithTranslateComponent} from '../zen-base-with-translate/zen-base-with-translate.component';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-zen-contact-form-fields',
  templateUrl: './zen-contact-form-fields.component.html',
  styleUrls: ['./zen-contact-form-fields.component.scss']
})
export class ZenContactFormFieldsComponent extends ZenBaseWithTranslateComponent implements OnInit, OnChanges, OnDestroy {
  @Input() form: UntypedFormGroup;
  @Input() formType: ZenFormTypesEnum;
  @Input() hideActionBtns = false;
  @Output() cancelEdit = new EventEmitter();
  @Output() onContactFormSubmit = new EventEmitter();
  @Input() isMultiCustomerContact = false;
  @Input() loading: boolean;
  @Input() customerId: number;

  unavailableError = 'Unavailable';
  unavailableToCustomerError = 'Unavailable to customer';
  private subscription: Subscription;

  appearance: MatFormFieldAppearance = 'outline';
  ZenMessageType = ZenMessageType;
  NoSpacesMask = NoSpacesMask;

  constructor(private signupSvc: SignupService,
              private authSvc: AuthenticationService,
              public translateSvc: TranslateService
  ) {
    super(translateSvc);
  }

  ngOnInit(): void {
    // Email change validation
    this.subscription = this.getEmailSubscription();

    if (this.isMultiCustomerContact && !this.authSvc.isAdvisor()) {
      this.getBasicFormFields().forEach(control => {
        control.disable();
      });
    }
  }

  getEmailSubscription(): Subscription {
    return this.form.controls.email.valueChanges.pipe(debounceTime(500), distinctUntilChanged())
      .subscribe(email => {
        if (this.form.controls.email.valid) { // Only check if email is valid
          this.signupSvc.signupEmailCheck(this.form.controls.email.value, this.customerId).subscribe(res => {
            if (!res.result) {
              this.signupSvc.signupRetrieveUserByEmail(this.form.controls.email.value).subscribe(user => {
                if (user == null) {
                  if (res.errorMessage === this.unavailableError) {
                    this.form.controls.email.setErrors({existentAccount: true});
                  } else {
                    this.form.controls.email.setErrors({customerExistentAccount: true});
                  }
                } else {
                  this.form.controls.firstName.setValue(user.firstName);
                  this.form.controls.lastName.setValue(user.lastName);
                  this.form.controls.title.setValue(user.title);
                  this.form.controls.phone.setValue(user.phone);
                  // Disable fields
                  if (this.formType === ZenFormTypesEnum.ADD) {
                    this.getBasicFormFields().forEach(control => {
                      if (control.value != null && control.value !== '') {
                        control.disable();
                      } else {
                        control.enable(); // Enable for empty values so we can capture those here
                      }
                    });
                  } else {
                    if (this.authSvc.isAdvisor() || !this.isMultiCustomerContact) {
                      this.getBasicFormFields().forEach(control => control.enable());
                    }
                  }
                  this.form.controls.userId.setValue(user.id);
                }
              })
            } else {
              if (this.form.controls.firstName.disabled) {
                this.getBasicFormFields().forEach(control => {
                  control.enable();
                  control.setValue(null);
                });
              }
            }
            this.form.controls.userId.setValue(null);
          })
      } else {
      this.formEditLogic();
    }
  });
}

  getBasicFormFields(): AbstractControl[] {
    return [this.form.controls.firstName,
      this.form.controls.lastName,
      this.form.controls.title,
      this.form.controls.phone];
  }

  hasError(formControlName: string, formErrorName: string): boolean {
    return zenHasError(this.form, formControlName, formErrorName);
  }

  formEditLogic() {
    if (this.isMultiCustomerContact && !this.authSvc.isAdvisor() && this.formType === ZenFormTypesEnum.EDIT) {
      this.form.controls.email.disable();
    } else {
      this.form.controls.email.enable();
      this.getBasicFormFields().forEach(control => {
        if (control.disabled) {
          control.enable();
          if (this.form.controls.email.value === '') {
            control.setValue(null);
          }
        }
      });
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.formEditLogic();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
