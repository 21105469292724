import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LayoutComponent} from './layout/layout.component';
import {RouterModule} from '@angular/router';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatMenuModule} from '@angular/material/menu';
import {MatDialogModule} from '@angular/material/dialog';
import {ZenDialogMsgService} from '../_shared/_services/zen-dialog-msg.service';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatChipsModule} from '@angular/material/chips';
import {MatIconModule} from '@angular/material/icon';
import {NgxMaskModule} from 'ngx-mask';
import {SharedModule} from '../_shared/shared.module';
import {ZenFooterComponent} from './zen-footer/zen-footer.component';

@NgModule({
  declarations: [
    LayoutComponent,
    ZenFooterComponent
  ],
  exports: [
    LayoutComponent,
    ZenFooterComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    MatSidenavModule,
    MatSlideToggleModule,
    MatMenuModule,
    MatDialogModule,
    MatSnackBarModule,
    MatChipsModule,
    MatIconModule,
    NgxMaskModule.forRoot(),
    SharedModule
  ],
  providers: [ZenDialogMsgService]
})
export class LayoutModulesModule {
}
