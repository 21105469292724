<app-zen-dialog-wrapper [loading]="loading"  [data]="config" [actionStyleCls]="'center'" [hideButton]="true">
  <app-zen-loader *ngIf="loading"></app-zen-loader>
  <form class="mx-5 mx-sm-0 zen-request-refresh-dialog-form" *ngIf="form" [formGroup]="form">
    <p class="text-md ms-1 mt-3 mb-5">To initiate a refresh for {{customer?.companyName}}, select the pricing,
      product, and {{'nomenclature.supplier' | translate}} options you would like to refresh.</p>

    <div class="row mx-5">
      <p class="text-md font-weight-600 mb-3 text-start">Pricing</p>
      <div class="col-lg-4 col-md-4 col-sm-12">
        <mat-form-field monthYearDatePicker appearance="outline">
          <mat-label>Contract Start Date*</mat-label>
          <input matInput name="contract-start" [matDatepicker]="dp" readonly (click)="dp.open()"
                 formControlName="contractStart"
                 [min]="minContractStart">
          <mat-datepicker-toggle matIconSuffix [for]="dp"></mat-datepicker-toggle>
          <mat-datepicker #dp startView="year" (monthSelected)="setMonthAndYear($event, dp);">
          </mat-datepicker>
        </mat-form-field>
      </div>
      <!-- Term Picker -->
      <div class="col-lg-7 col-md-4 col-sm-12">
        <div class="form-group">
          <app-zen-input-chip-list-selection label="Term(s)*"
                                             [formCtrl]="form.controls.startingTerms"
                                             [compactSize]="true"
                                             [infoPopper]="termsInfoPopper"
                                             [maxSelection]="6"
                                             [onlyNumbers]="true"
                                             [itemListData]="contractTermsList">
          </app-zen-input-chip-list-selection>
        </div>
      </div>
    </div>
    <div class="row mx-5">
      <!-- Request Due Date -->
      <div class="col-lg-4 col-md-4 col-sm-12">
        <app-zen-date-time-picker *ngIf="minContractDateString" [label]="label" [dateMode]="mode" [required]="true"
                                  [contractStartString]="minContractDateString"
                                  [dateCtrl]="form.controls.dueDateCtrl" [timeCtrl]="form.controls.dueDateTimeCtrl"
                                  [commodityType]="commodityType" [isRefresh]="true"></app-zen-date-time-picker>
      </div>

      <div *ngIf="!isElectric" class="col-lg-3 col-md-3 col-sm-11" >
        <mat-form-field appearance="outline">
          <mat-label>Payment Terms*</mat-label>
          <mat-select formControlName="paymentTerms">
            <mat-option *ngFor="let po of paymentTermsOptions" [value]="po.value">{{po.label}}</mat-option>
          </mat-select>
          <i class="material-symbols-rounded" matSuffix>expand_more</i>
          <mat-hint *ngIf="form.controls.paymentTerms.value !== ZenNatGasPaymentTermEnum.NET_15">
                <span class="d-flex">
                  <i class="material-icons me-2 danger-1-color">warning</i>
                  Suppliers will add a fee to cover the credit risk associated with this payment term.
                </span>
          </mat-hint>
        </mat-form-field>
      </div>

      <div class="col-lg-3 col-md-3 col-sm-11 field-with-icon" [class.total-fee-spacing]="!isElectric">
        <mat-form-field appearance="outline">
          <mat-label>Billing Method*</mat-label>
          <mat-select formControlName="billingMethod">
            <mat-option *ngFor="let bm of billingMethods" [value]="bm.value">{{bm.label}}</mat-option>
          </mat-select>
          <i class="material-symbols-rounded" matSuffix>expand_more</i>
        </mat-form-field>
        <i class="material-icons-outlined mat-form-field-info-icon"
           [popper]="billingMethodInfoPopper" [popperHideOnScroll]="true" [popperHideOnClickOutside]="true"
           popperApplyClass="sm" popperPlacement="bottom">info</i>
      </div>

      <div class="col-lg-3 col-md-3 col-sm-11 field-with-icon" [class.total-fee-spacing]="isElectric" [class.mt-3]="!isElectric">
        <mat-form-field appearance="outline">
          <mat-label>Total fees {{isElectric ? '(mils)' : '($)'}}</mat-label>
          <input matInput formControlName="totalFee" name="totalFee" type="number" step="0.01"
                 [imask]="FeeMask" [unmask]="true"
                 placeholder="Example: 0.25" (change)="recalculateFees()"/>
          <div class="fee-error" *ngIf="feeError.hasError">
            <app-zen-total-fee-error [data]="{feeError, feeDetails, isElectric}"></app-zen-total-fee-error>
          </div>
        </mat-form-field>

        <app-zen-total-fee-breakdown-popper-icon [feeDetails]="feeDetails"
                                                 [feeError]="feeError"
                                                 [rateCheckData]="{
                                                    commodity: commodityType,
                                                    rateCheckId: config.data?.rateCheckId,
                                                    customerId: config.data?.customerId,
                                                    currentTotalFee: form.controls.totalFee.value,
                                                    rateCheckUnit: commodityType == CommodityType.Gas ? ZenUnitsEnum.Dth : ZenUnitsEnum.MWh
                                                  }"
                                                 (onSubmit)="updateFee($event)"></app-zen-total-fee-breakdown-popper-icon>

      </div>
    </div>
    <div class="row mx-5 mt-5 mb-3">
      <div class="col-12 col-sm-12 col-md-8 col-lg-8 flex-column">
      <p class="text-md font-weight-600 mb-3 text-start">Product</p>
      </div>
      <div class="col-12 col-sm-12 col-md-4 col-lg-4 flex-column">
        <p class="text-md font-weight-600 mb-3 text-start">Suppliers ({{suppliers.length}})</p>
      </div>
    </div>
    <div class="row mx-5">
      <!-- Left Column: Product and Comments -->
      <div class="col-12 col-sm-12 col-md-8 col-lg-8 flex-column">
        <!-- Product Section -->
        <div class="product-section">
          <app-zen-icon-button-toggle-group class="mb-3" [title]="isElectric ? 'Renewable Energy Credits' : 'Carbon Offsets'"
                                            [icon]="ZenIcons.GREEN"
                                            [formCtrl]="form.controls.renewable"
                                            [popperComponent]="renewableInfo"
                                            [options]="renewableOptions">
          </app-zen-icon-button-toggle-group>
        </div>

        <!-- Comments Section -->
        <div class="mt-3">
          <mat-form-field appearance="outline" class="zen-mat-textarea">
            <mat-label>Comments</mat-label>
            <textarea matInput [formControl]="form.controls.comments" class="refresh-details" rows="5"
                      placeholder="For Example: Refresh Engie's 12-, 24-, and 26-month rates for a client meeting on 12/1 - need pricing same day by 10:00 AM Eastern"></textarea>
          </mat-form-field>
          <div class="d-flex align-items-center justify-content-between">
            <mat-error *ngIf="form.controls.comments.touched && form.controls.comments.errors && form.controls.comments.errors.maxlength">
              Maximum allowed characters exceeded.
            </mat-error>
            <mat-hint>{{CHAR_LIMIT}} Character Limit</mat-hint>
          </div>
        </div>
      </div>

      <!-- Right Column: Suppliers List -->
      <div class="col-12 col-sm-12 col-md-4 col-lg-4">
        <div class="suppliers-section custom-scroll">
          <mat-selection-list #suppliersList [formControl]="form.controls.suppliersControl" class="supplier-list p-0">
            <mat-list-option class="supplier-option" togglePosition="before" *ngFor="let supplier of suppliers" [value]="supplier.value">
              {{ supplier.label }}
              <span *ngIf="supplier.incumbentSupplier" class="incumbent-supplier-asterisk" matTooltip="Incumbent {{'nomenclature.supplier' | translate}}" tooltipPosition="above"> *</span>
            </mat-list-option>
          </mat-selection-list>
        </div>
      </div>
    </div>

    <div class="d-flex align-items-center justify-content-end me-3">
      <button mat-button class="me-5" type="button" (click)="dialogRef.close()">Cancel</button>
      <button mat-raised-button color="accent" type="button"
              [disabled]="!form.controls.comments.valid  && !form.controls.dueDateCtrl.valid && !form.controls.dueDateTimeCtrl.valid"
              (click)="submit()">Submit
      </button>
    </div>
  </form>
</app-zen-dialog-wrapper>

<popper-content #termsInfoPopper>
  <app-zen-popper [popperContent]="termsInfoPopper">
    <p class="card-title mb-2">Term(s)</p>
    <p class="card-title mb-2 text-md">Maximum Number of Terms: 6</p>
    <p class="mb-0 text-md">
      <span class="card-title">Staggered Contract Start Implications:</span> These scenarios may lead to certain terms
      being ineligible as all Contract Starts must be covered by the term to be eligible. For example, terms between 1
      and 7 months are ineligible when a request includes a Contract Start in January and another in August.</p>
  </app-zen-popper>
</popper-content>
<popper-content #renewableInfo>
  <app-zen-popper [popperContent]="renewableInfo">
    <p class="card-title">Renewable Energy Credits (RECs)</p>
    <div class="text-md mb-0">
      <p>Your choice here will determine if your rate includes Renewable Energy Credits (RECs), supporting the generation of renewable energy.</p>
      <p><br /><b>Included - Only Price with RECs:</b> By selecting this option, your rate will include Renewable Energy Credits. This means a portion of your electricity consumption is matched with RECs, supporting renewable energy projects.</p>
      <p><br /><b>Excluded - Price without RECs:</b> If you choose this option, your rate will not include Renewable Energy Credits. This means your electricity consumption will not be directly supporting renewable energy projects through RECs.</p>
      <p><br /><b>Compare - Price with and without RECs:</b> Unsure which option to choose? Select this to see a comparison of rates with and without RECs included. This will help you make an informed decision based on the cost difference and your personal or business sustainability goals.</p>
      <p><br />Please note that including RECs does not directly affect the source of your electricity but supports the overall production of renewable energy.</p>
    </div>
  </app-zen-popper>
</popper-content>
<popper-content #billingMethodInfoPopper>
  <app-zen-popper [popperContent]="billingMethodInfoPopper">
    <p class="card-title mb-2">Billing Method</p>
    <p class="mb-0 text-md">
      <span class="card-title">Dual Billing (Recommended)</span> – This less expensive option results in two separate
      bills sent from the supplier, for the commodity, and from the utility for all other components.
      <span class="card-title">Consolidated Billing</span> – This utility-specific option is known as Purchase of
      Receivables, or POR, and includes both the commodity and utility components. This marginally more expensive option
      creates a single billing option sent by the utility to the customer. Each month the utility will pay the supplier
      for the customer’s commodity usage, often times before the client ever pays the utility invoice. The risk
      associated with non-payment is minimized with the application of a POR Fee which is built into the supplier
      pricing and allows the utility to record their costs and maintain cash flow.
    </p>
  </app-zen-popper>
</popper-content>
