import {Component, HostListener, Input, OnDestroy, OnInit} from '@angular/core';
import {Tag} from '../../../_modules/portfolio/_model/tag.model';
import {TagService} from '../../_services/tag.service';
import {ZenDialogMsgService} from '../../_services/zen-dialog-msg.service';
import {ZenBreadcrumbService} from '../../_services/zen-breadcrumb.service';
import {UntypedFormControl} from '@angular/forms';
import {
  ButtonActionTypes,
  ButtonTypes,
  ZenDialogDataModel,
  ZenDialogDataType
} from '../../_dialogs/zen-dialog/zen-dialog.component';
import {
  ZenImageCropperDialogComponent
} from '../../_dialogs/zen-image-cropper-dialog/zen-image-cropper-dialog.component';
import {ZenDialogPanelClassEnum, ZenDialogSizeEnum} from '../../_enums/zen-dialogs.enum';
import {MatDialog} from '@angular/material/dialog';
import {AuthenticationService} from '../../_zen-legacy-common/zen-common-services/_services/authentication.service';
import {CustomerIdSessionService} from '../../_services/customer-id/customer-id-session.service';
import {ZenPortfolioExpansionDetails} from '../../_model/custom/zen-detail-expansion.model';
import {ActivatedRoute} from '@angular/router';


@Component({
  selector: 'app-zen-portfolio-detail-expansion',
  templateUrl: './zen-portfolio-detail-expansion.component.html',
  styleUrls: ['./zen-portfolio-detail-expansion.component.scss']
})
export class ZenPortfolioDetailExpansionComponent implements OnInit, OnDestroy {
  @Input() expansionDetails: ZenPortfolioExpansionDetails;
  THUMBNAIL = '/assets/img/img-thumbnail.svg';
  expanded: boolean;
  isDesktop: boolean;
  inputCtrl = new UntypedFormControl();
  toolTip: string;

  constructor(private tagSvc: TagService, private dialogSvc: ZenDialogMsgService,
              private authSvc: AuthenticationService,
              private customerIdSvc: CustomerIdSessionService,
              private route: ActivatedRoute,
              private zenBcSvc: ZenBreadcrumbService, private dialog: MatDialog) {
  }

  ngOnInit(): void {
    window.scrollTo(0, 0);
    this.checkForDesktopView();
    if (this.route.snapshot.params?.customerId) {
      this.customerIdSvc.setCustomerIdFromParams(this.route.snapshot.params?.customerId);
    } else {
      this.customerIdSvc.reset();
    }
  }

  ngOnDestroy() {
    // For advisor need to reset the customer id store
    if (this.authSvc.isAdvisor()) {
      this.customerIdSvc.reset();
    }
  }

  toggleExpansion() {
    this.expanded = !this.expanded;
  }

  // To check desktop view and to hide menu option w.r.t, hideForDesktop flag.
  @HostListener('window:resize')
  checkForDesktopView() {
    this.isDesktop = window.innerWidth > 1249;
  };

  onTagRemove(tagToRemove: Tag) {
    this.tagSvc.deleteTag(tagToRemove.customerId, tagToRemove.id, {tagType: tagToRemove.objectType, name: tagToRemove.name}).subscribe(() => {
      const i = this.expansionDetails.tags.findIndex(t => t.id === tagToRemove.id);
      if (i !== -1) {
        this.expansionDetails.tags.splice(i, 1);
      }
      this.dialogSvc.openToast(true);
    }, e => {
      console.log('Error: Update Tags ', e);
      this.dialogSvc.openToast(false);
    });
  }

  onFileChange(filesEvt) {
    if (filesEvt.target.files.length === 0) {
          return;
    }
    let dialogData: ZenDialogDataModel = {
      type: ZenDialogDataType.CONFIRM,
      header: {title: 'Crop Image'},
      data: {imageInputFileEvent: filesEvt, aspectRatio: 1},
      onClose: () => this.closeDialogAndResetFileInput(dialogRef),
      actionButtons: [
        {
          label: 'Close', btnType: ButtonTypes.MAT_BUTTON, actionType: ButtonActionTypes.CANCEL,
          command: () => this.closeDialogAndResetFileInput(dialogRef)
        },
        {
          label: 'Confirm', btnType: ButtonTypes.MAT_RAISED_BUTTON, color: 'primary',
          actionType: ButtonActionTypes.SUBMIT,
          command: (croppedImg: File) => {
            this.expansionDetails.onImageUpload(croppedImg);
            this.closeDialogAndResetFileInput(dialogRef);
          }
        }
      ]
    };

    const dialogRef = this.dialog.open(ZenImageCropperDialogComponent, {
      width: ZenDialogSizeEnum.EXTRA_MEDIUM,
      panelClass: ZenDialogPanelClassEnum.MOBILE_FULL_HEIGHT,
      data: dialogData, autoFocus: false
    });
  }

  private closeDialogAndResetFileInput(dialogRef) {
    dialogRef.close();
    this.inputCtrl.reset();
  }

  handleImageRevert() {
    this.dialogSvc.showConfirmationDialog(
      'Clear Image',
      'Are you sure you want to clear the image? This will reset the image back to the default icon. This is an irreversible action.',
      () => {},
      () => {
        this.expansionDetails.onImageRevert();
      }
    )
  }

  /** This method find Edit menu items from the expansionDetails.menuItems and trigger the command */
  handleEdit() {
    const editMenuItems = this.expansionDetails.menuItems.find(m => m.label === 'Edit');
    const childMenuItems = editMenuItems?.children.find(m => m.label === 'Edit');
    if (childMenuItems) {
      childMenuItems.command(this.expansionDetails.currentHierarchyDetails);
    }
  }

  setTooltip(toolTip: string) {
    // The replace is specific to omitting the 'Yes | ' as seen in the agentLanguageRequired tooltip
    this.toolTip = toolTip.replace('Yes | ', '');
  }
}
