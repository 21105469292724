<app-zen-dialog-wrapper [data]="data" (onSubmit)="submit($event)" [disableButton]="disableButton"
                        [errorMsg]="errorMsg" [loading]="disableButton">
  <div class="d-flex flex-wrap" *ngIf="form" [formGroup]="form">
    <div class="col-12 col-md-6 px-4">
      <!-- Associated with section - is hidden if both customerId and lenId known. -->
      <div class="dialog-form-sec with-border" [hidden]="lockedCustomerId && lockedLenId">
        <div class="d-flex justify-content-center">
          <div class="w-100">

            <div class="row mb-3">
              <div class="col-12 d-flex align-items-center justify-content-between">
                <p class="form-title mb-0">Associated with...</p>
                <button *ngIf="lenCtrl.value" class="filter-button d-flex align-items-center p-0"
                        (click)="toggleShowMore()">
                  <span class="small">{{showMore ? 'Show less' : 'Show more'}}</span>
                  <i class="material-icons small ms-1">{{showMore ? 'chevron_right' : 'expand_more'}}</i>
                </button>
              </div>
            </div>

            <!-- Customer form -->
            <div class="row text-start form-group">
              <div class="col-12 mb-2" *ngIf="showMore else lockedCustTpl">
                <mat-form-field [appearance]="appearance" class="w-100"
                                [class]="lockedCustomerId ? 'locked' : ''">
                  <mat-label>{{authSvc.customerNameLabel}}*</mat-label>
                  <input *ngIf="lockedCustomerId" matInput [value]="customerCtrl.value?.value"
                         [readonly]="lockedCustomerId">
                  <ng-container *ngIf="!lockedCustomerId">
                    <input type="text"
                           matInput
                           [formControl]="customerCtrl"
                           [matAutocomplete]="auto">
                    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
                      <mat-option *ngFor="let option of filteredCustomers | async" [value]="option">
                        {{option.value}}
                      </mat-option>
                    </mat-autocomplete>
                  </ng-container>
                  <i class="material-symbols-rounded" matSuffix>expand_more</i>
                  <mat-error *ngIf="customerCtrl.touched && customerCtrl.hasError('required')">Required Field*
                  </mat-error>
                  <mat-error *ngIf="customerCtrl.touched && customerCtrl.hasError('validoption')">Invalid Customer*
                  </mat-error>
                </mat-form-field>
              </div>

              <ng-template #lockedCustTpl>
                <div class="locked-input-value">
                  <div class="d-flex align-items-center">
                    <p class="m-0 d-flex align-items-center text-md">
                      <i class="material-symbols-rounded ms-1">{{ZenIcons.CUSTOMER}}</i>
                      <span class="ms-2">{{customerCtrl.value?.value}}</span>
                    </p>
                  </div>
                </div>
              </ng-template>
            </div>

            <ng-container *ngIf="customerCtrl.valid && showMore">
              <div class="row text-start">
                <div class="col-12 mb-2">
                  <mat-form-field class="w-100" [appearance]="appearance" [class]="lockedLenId?'locked':''">
                    <mat-label>Legal Entity Name*</mat-label>
                    <input *ngIf="lockedLenId" matInput [value]="lenCtrl.value?.value" [readonly]="lockedLenId">
                    <ng-container *ngIf="!lockedLenId">
                      <input type="text" matInput
                             (input)="copyLenName($event.target.value)"
                             [formControl]="lenCtrl" [matAutocomplete]="lenAuto">
                      <mat-autocomplete #lenAuto="matAutocomplete" [displayWith]="displayFn">
                        <!-- Mat select Add new LEN -->
                        <mat-option class="action-button" (click)="handleAddNewLen()">
                          <i class="material-icons-round">add</i>New Legal Entity Name
                        </mat-option>
                        <mat-option *ngFor="let option of filteredLens | async" [value]="option">
                          {{option.value}}
                        </mat-option>
                      </mat-autocomplete>
                    </ng-container>
                    <i class="material-symbols-rounded" matSuffix>expand_more</i>
                    <mat-error *ngIf="lenCtrl.touched && lenCtrl.hasError('required')">Required Field*</mat-error>
                    <mat-error *ngIf="lenCtrl.touched && lenCtrl.hasError('validoption')">Invalid LEN*</mat-error>
                  </mat-form-field>
                </div>
              </div>
            </ng-container>

          </div>
        </div>
      </div>

      <div class="dialog-form-sec with-border">
        <div class="d-flex justify-content-center">
          <div class="justify-content-center">

            <div class="row mb-3">
              <div class="col-12 text-start">
                <p class="form-title mb-0">Service Address Details
                  <i class="material-icons-outlined help-outline ms-2"
                     [popper]="serviceAddressDetailsPopper"
                     [popperHideOnScroll]="true"
                     [popperHideOnClickOutside]="true"
                     popperTrigger="click"
                     popperApplyClass="sm"
                     popperPlacement="bottom">info</i>
                </p>
              </div>
            </div>

            <!-- Service Address details form -->
            <div class="row text-start">
              <div class="col-12 form-group">
                <mat-form-field [appearance]="appearance">
                  <mat-label>Service Address Name</mat-label>
                  <input matInput formControlName="label">
                </mat-form-field>
              </div>
              <div class="col-12 form-group">
                <mat-form-field [appearance]="appearance">
                  <mat-label>Service Address 1*</mat-label>
                  <input matInput formControlName="street1" #address
                         (keyup)="addressAutocompleteService.handleAddressChange(form, address, AddressFormType.STREET)">
                  <mat-error *ngIf="hasError('street1', 'required')">Required
                    Field*
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="col-6 form-group">
                <mat-form-field [appearance]="appearance">
                  <mat-label>Service Address 2</mat-label>
                  <input matInput formControlName="street2">
                </mat-form-field>
              </div>
              <div class="col-6 col-md-6 form-group">
                <mat-form-field [appearance]="appearance">
                  <mat-label>Service City*</mat-label>
                  <input matInput formControlName="city">
                  <mat-error *ngIf="hasError('city', 'required')">Required
                    Field*
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="col-6 col-md-6 form-group">
                <mat-form-field [appearance]="appearance">
                  <mat-label>Service {{'nomenclature.state' | translate}}*</mat-label>
                  <input matInput [formControl]="controls.state" [matAutocomplete]="stateAuto">
                  <mat-autocomplete #stateAuto="matAutocomplete" [panelWidth]="100" [autoActiveFirstOption]="true">
                    <mat-option *ngFor="let option of filteredStateOptions | async" [value]="option">
                      {{option}}
                    </mat-option>
                  </mat-autocomplete>
                  <i class="material-symbols-rounded" matSuffix>expand_more</i>
                  <mat-error *ngIf="hasError('state', 'required')">Required
                    Field*
                  </mat-error>
                  <mat-error *ngIf="hasError('state', 'validoption')">Invalid {{'nomenclature.state' | translate}}*
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="col-6 col-md-6 form-group">
                <mat-form-field [appearance]="appearance">
                  <mat-label>Service Zip*</mat-label>
                  <input matInput formControlName="zip" [mask]="i18nMask.zip">
                  <mat-error *ngIf="hasError('zip', 'required')">Required
                    Field*
                  </mat-error>
                  <mat-error *ngIf="hasError('zip', 'pattern')">Invalid
                    Format*
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-12 col-md-6 px-4">
      <div class="dialog-form-sec with-border">
        <div class="text-start mb-3">
          <p class="form-title mb-0">Additional Details</p>
        </div>

        <div class="form-group">
          <app-zen-select-search-dropdown [formCtrl]="form.controls.subType"
            (onOptionChange)="setServiceAddressTypeAndSubtype($event.split('||')[0], $event.split('||')[1])"
            [filterFn]="filterTypes"
            [initialOptions]="serviceAddressTypeOptions"
            [isSearchable]="true"
            [label]="'Service Address Type*'"
            [preselectedOption]="preselectedSubtypeString"
            [showError]="hasError('subType', 'required')"
          ></app-zen-select-search-dropdown>
        </div>

        <div class="form-group">
          <mat-form-field [appearance]="appearance">
            <mat-label>Activation Status*</mat-label>
            <mat-select formControlName="active" (selectionChange)="handleSelectionChange($event)">
              <mat-option [value]="true" class="py-2">Active</mat-option>
              <mat-option [value]="false" class="py-2">Inactive</mat-option>
            </mat-select>
              <mat-hint *ngIf="disableActivationStatus" class="text-color mt-2">
                Parent must be active in order to activate.</mat-hint>
              <mat-hint *ngIf="activatedServiceAddress" class="text-color mt-2">
                You are about to <b>reactivate</b> this service address. The platform will also <b>reactivate</b> the underlying records.</mat-hint>
              <mat-hint *ngIf="deactivatedServiceAddress" class="text-color mt-0">
                You are about to <b>deactivate</b> this service address. Any underlying records associated with your service address will also be <b>deactivated</b>.</mat-hint>
            <i class="material-symbols-rounded" matSuffix>expand_more</i>
            <mat-error *ngIf="hasError('active', 'required')">Required Field*</mat-error>
          </mat-form-field>
        </div>

        <!-- For already inactive LENs we are hiding this reason fields form. -->
        <div class="mt-5" *ngIf="serviceAddressDetailsModel && !form.controls.active.value">
          <app-zen-activation-form [hierarchyLevel]="PortfolioHierarchyLevelEnum.SERVICE_ADDRESSES" [activationAudit]="serviceAddressDetailsModel.activationAudit" [form]="activationChange"></app-zen-activation-form>
        </div>
      </div>

      <div class="dialog-form-sec with-border">
        <div class="zen-mat-form">
          <app-zen-tags
            [type]="TagType.SERVICE_ADDRESS"
            [tagFilterCtrl]="tagFilterCtrl"
            [tagOptions]="tagOptions"
            [selectedTags]="selectedTags"
            (onAddTag)="handleAddTag($event)"
            (onRemoveTag)="handleRemoveTag($event)"></app-zen-tags>
        </div>
      </div>
    </div>

  </div>
</app-zen-dialog-wrapper>

<popper-content #serviceAddressDetailsPopper>
  <app-zen-popper [closeable]="true" [popperContent]="serviceAddressDetailsPopper">
    <p class="font-weight-600 mb-1">Service Address Name</p>
    <p class="medium mb-4">
      Define a unique name to clearly identify service addresses within a portfolio.
    </p>
    <p class="font-weight-600 mb-1">Service Address</p>
    <p class="medium mb-4">
      Define a unique address for each service address based on the location of service delivery. Each meter associated to this
      service address will inherit the Address 1, City State and Zip.
    </p>
    <p class="font-weight-600 mb-1">Service Address Type</p>
    <p class="medium mb-4">
      Further classify real estate customers by defining the service address type as either “commercial” or “multi-family”. The
      field will not be present if the customer type is not real estate.
    </p>
    <p class="font-weight-600 mb-1">Service Address Image</p>
    <p class="medium mb-4">
      Easily identify a specific service address by replacing the default with an image of the service address. Format: JPEG or PNG |
      Min Width: 500 px (Ratio - 3:1)
    </p>
    <p class="font-weight-600 mb-1">Activation Status</p>
    <p class="medium mb-4">
      Organize your portfolio with an Activation Status. Records set a status of inactive will be ineligible for
      procurement and hidden from view.
    </p>
  </app-zen-popper>
</popper-content>

<popper-content #serviceDetailsInfo>
  <app-zen-popper title="Service Details - Zones" [closeable]="true" [popperContent]="serviceDetailsInfo">
    <p class="medium">
      Zones are established by some Independent System Operators (ISOs) to define specific load territories, each with
      its own demand profile and location-specific pricing. Matrix {{'nomenclature.suppliers' | translate}} price based on zone in New York, Texas, and
      Massachusetts, making this a requirement for matrix contracting.
    </p>
  </app-zen-popper>
</popper-content>
