<app-zen-dialog-wrapper [data]="data" (onSubmit)="submit($event)" [actionStyleCls]="'center'">

  <mat-tab-group disableRipple  *ngIf="products && products.length > 1" class="nat-gas-report-tabview v1"
                 [(selectedIndex)]="tabActiveIndex">
    <mat-tab *ngFor="let product of products; let i = index"
             [className]="product.tabCustomStyle ? 'custom-' + product.tabCustomStyle : null">
      <ng-template mat-tab-label>
        <div class="header d-flex align-items-center">
          <i *ngIf="product.tabIconType === 'font' && i !== tabActiveIndex" class="material-icons list_alt">{{
            product.unselectedTabIcon }}</i>
          <i *ngIf="product.tabIconType === 'font' && i === tabActiveIndex" class="material-icons list_alt">{{
            product.selectedTabIcon }}</i>
          <img *ngIf="product.tabIconType === 'image' && i !== tabActiveIndex" class="icon-img"
               src="{{ product.unselectedTabIcon }}">
          <img *ngIf="product.tabIconType === 'image' && i === tabActiveIndex" class="icon-img"
               src="{{ product.selectedTabIcon }}">
          <div class="d-flex flex-column">
            <span class="m-0">{{ product.tabName }}</span>
          </div>
        </div>
      </ng-template>
      <div class="table-container">
        <ng-template [ngTemplateOutlet]="downloadBtnTpl"></ng-template>
        <app-zen-mat-table *ngIf="product.tableConfig"
                           [data]="product.tableModel.data" [tableConfig]="product.tableConfig"></app-zen-mat-table>
      </div>
    </mat-tab>
  </mat-tab-group>

  <ng-container *ngIf="products?.length === 1 && products?.[0]?.tableConfig">
    <div class="table-container mt-4">
      <ng-template [ngTemplateOutlet]="downloadBtnTpl"></ng-template>
      <app-zen-mat-table [data]="products[0].tableModel.data"
                         [tableConfig]="products[0].tableConfig"></app-zen-mat-table>
    </div>
  </ng-container>

</app-zen-dialog-wrapper>

<ng-template #downloadBtnTpl>
<!--  [class.more-than-one]="products && products.length > 1"-->
  <div class="download-wrapper">
    <i class="material-symbols-rounded color-icon bg-secondary-color p-2 pointer"
       (click)="extractExcel()">download</i>
  </div>
</ng-template>
