import {Injectable} from '@angular/core';
import {combineLatest} from 'rxjs';
import {ButtonTypes, ZenDialogComponent, ZenDialogDataModel} from '../../../_shared/_dialogs/zen-dialog/zen-dialog.component';
import {ZenIconsEnum} from '../../../_shared/_enums/zen-icons.enum';
import {
  ZenEditCustomerMemberDialogComponent
} from '../../../_shared/_dialogs/zen-edit-customer-member-dialog/zen-edit-customer-member-dialog.component';
import {ZenDialogPanelClassEnum, ZenDialogSizeEnum} from '../../../_shared/_enums/zen-dialogs.enum';
import {CustomerContact} from '../../../_shared/_zen-legacy-common/_models/customer';
import {CustomerIdSessionService} from '../../../_shared/_services/customer-id/customer-id-session.service';
import {CustomerContactService} from '../../../_shared/_zen-legacy-common/zen-common-services/_services/customer-contact.service';
import {LenService} from '../../../_shared/_zen-legacy-common/zen-common-services/tili-services/services/len.service';
import {ZenDialogMsgService} from '../../../_shared/_services/zen-dialog-msg.service';
import {MatDialog} from '@angular/material/dialog';
import {LenModel} from '../../../_shared/_zen-legacy-common/zen-common-services/tili-services/models/len';
import {MessageIconsEnum} from '../../../_shared/_zen-legacy-common/_enums/message-icons.enum';
import {
  ZenCustomerMemberDeleteDialogComponent
} from '../../../_shared/_dialogs/zen-customer-member-delete-dialog/zen-customer-member-delete-dialog.component';
import {TranslateService} from '@ngx-translate/core';
import {i18nFormatPhoneNumber} from '../../../_shared/_components/zen-base-with-translate/zen-base-with-translate.component';
import {ZenLocaleModel} from '../../../_shared/_model/zen-locale.model';

@Injectable({
  providedIn: 'root'
})
export class ZenCustomerUserHelperService {
  loading: boolean;
  initialLoading = false;
  customerContacts: CustomerContact[] = [];
  customerLens: LenModel[] = [];

  constructor(private customerContactSvc: CustomerContactService,
              private customerLenSvc: LenService,
              private customerIdSvc: CustomerIdSessionService,
              private zenDialogSvc: ZenDialogMsgService,
              private dialog: MatDialog,
              private translateSvc: TranslateService
  ) {
  }


  init() {
    const _translations = Object.values(this.translateSvc.translations)?.[0] as ZenLocaleModel;
    this.loading = true;
    this.initialLoading = true;
    const _customerId = this.customerIdSvc.getCustomerId();
    combineLatest([
      this.customerContactSvc.getCustomerContacts(_customerId),
      this.customerLenSvc.getAllCustomerLens(_customerId)
    ]).subscribe(([contacts, lens]) => {
      this.customerContacts = contacts.map(c => {
        c.primaryIcon = c.primary ? ZenIconsEnum.STAR : null;
        c.primaryIconCls = c.primary ? 'material-icons green no-bg p-0 me-5' : null;
        return c;
      });

      this.customerLens = lens.map(len => {
        len.activeStatusCls = len.active ? 'active' : 'inactive';
        if (len.signator) {
          len.signatorName = `${len.signator?.firstName} ${len.signator?.lastName}${len.signator?.title?.trim() ? ', ' + len.signator?.title : ''}`;
          len.signatorEmailText = `${len.signator?.phone ? i18nFormatPhoneNumber(len.signator?.phone, _translations?.id) + ',' : ''} ${len.signator?.email}`;
        }
        if (len.contact) {
          len.contactNameText = `${len.contact?.firstName} ${len.contact?.lastName}${len.contact?.title?.trim() ? ', ' + len.contact?.title : ''}`;
          len.contactEmailText = `${len.contact?.phone ? i18nFormatPhoneNumber(len.contact?.phone, _translations?.id) + ',' : ''} ${len.contact?.email}`;
        }
        if (len.billingAddress) {
          len.billingAddressText = `${len.billingAddress?.address1}${len.billingAddress?.address2 ? ', ' + len.billingAddress?.address2 : ''}`;
          len.billingAddressSubText = `${len.billingAddress?.city}, ${len.billingAddress?.state} ${len.billingAddress?.zip}`;
        }

        // Adding len inside billing contact and contract signatory
        const contractSignatoryIndex = this.customerContacts.findIndex(c => c.id === len?.signator?.id);
        if (contractSignatoryIndex !== -1) {
          if (this.customerContacts[contractSignatoryIndex].contractSignatoryLens) {
            this.customerContacts[contractSignatoryIndex].contractSignatoryLens.push(len);
          } else {
            this.customerContacts[contractSignatoryIndex].contractSignatoryLens = [len];
          }
        }

        const billingContactIndex = this.customerContacts.findIndex(c => c.id === len?.contact?.id);
        if (billingContactIndex !== -1) {
          if (this.customerContacts[billingContactIndex].billingContactLens) {
            this.customerContacts[billingContactIndex].billingContactLens.push(len);
          } else {
            this.customerContacts[billingContactIndex].billingContactLens = [len];
          }
        }

        return len;
      });

      this.loading = false;
      this.initialLoading = false;
    }, e => {
      console.log('Error: Get customer contacts ', e);
      this.zenDialogSvc.openToast(false);
      this.loading = false;
      this.initialLoading = false;
    });
  }

  handleAddEditUser(rowData?: CustomerContact) {
    const editContact = this.customerContacts.find(c => c.id === rowData?.id);
    let dialogData: ZenDialogDataModel = {
      data: {
        contact: editContact,
        deleteCommand: () => {
          dialogRef.close();
          this.handleUserDelete(editContact);
        }
      },
      header: {
        title: editContact?.id ? 'Edit User' : 'Add User',
        icon: ZenIconsEnum.PERSON
      },
      bodyText: rowData ? `Edit the information below to maintain an accurate record of your user.`
        : `Add the information below to maintain an accurate record of your user.`,
      onClose: () => dialogRef.close()
    };

    const dialogRef = this.dialog.open(ZenEditCustomerMemberDialogComponent, {
      width: ZenDialogSizeEnum.MEDIUM,
      panelClass: [ZenDialogPanelClassEnum.MOBILE_FULL_HEIGHT],
      data: dialogData, autoFocus: false
    });

    dialogRef.afterClosed().subscribe((contact: CustomerContact) => {
      if (contact) {
        this.init();
      }
    });
  }

  handleUserDelete(rowData?: CustomerContact) {
    if (!rowData?.primary) {
      const _count = rowData.contractSignatoryLens?.length ? rowData.contractSignatoryLens?.length : rowData.billingContactLens?.length
      let dialogData: ZenDialogDataModel = {
        data: {contact: rowData},
        header: {title: 'Portfolio Management', icon: MessageIconsEnum.WARNING, iconClass: 'material-icons warn_1'},
        bodyHtml: `You are about to delete (${rowData.firstName} ${rowData.lastName}), who is currently assigned at least 1 role for (${_count}) Legal Entity Name${_count === 1 ? '' : 's'}.
       If you wish to continue, select a replacement user. Confirm to proceed.`,
        onClose: () => dialogRef.close()
      };

      const dialogRef = this.dialog.open(ZenCustomerMemberDeleteDialogComponent, {
        width: ZenDialogSizeEnum.SMALL,
        panelClass: [ZenDialogPanelClassEnum.MOBILE_FULL_HEIGHT],
        data: dialogData, autoFocus: false
      });

      dialogRef.afterClosed().subscribe((refresh: boolean) => {
        if (refresh) {
          this.init();
        }
      });
    } else {
      this.zenDialogSvc.openToast(false, 'Sorry, you cant delete primary contact.')
    }
  }

  handleSetPrimaryUser(rowData?: CustomerContact) {
    if (!rowData?.primary) {
      let dialogData: ZenDialogDataModel = {
        data: {contact: rowData},
        header: {title: 'Portfolio Management', icon: MessageIconsEnum.WARNING, iconClass: 'material-icons warn_1'},
        bodyHtml: `You are about to make (${rowData.firstName} ${rowData.lastName}), the primary user for this customer.  If this user is an advisor, customer emails will not have tokenized links.
        Confirm to proceed.`,
        onClose: () => dialogRef.close(),
        actionButtons: [
          {label: 'Cancel', btnType: ButtonTypes.MAT_BUTTON, command: () => dialogRef.close()},
          {
            label: 'Confirm', btnType: ButtonTypes.MAT_RAISED_BUTTON, color: 'primary',
            command: () => {
              dialogRef.close();
              this.customerContactSvc.makeContactPrimary(rowData.id, rowData.customerId).subscribe(response => {
                this.init();
              });
            }
          }
        ]
      };

      const dialogRef = this.dialog.open(ZenDialogComponent, {
        width: ZenDialogSizeEnum.SMALL,
        panelClass: [ZenDialogPanelClassEnum.MOBILE_FULL_HEIGHT],
        data: dialogData, autoFocus: false
      });

      dialogRef.afterClosed().subscribe((refresh: boolean) => {
        if (refresh) {
          this.init();
        }
      });
    } else {
      this.zenDialogSvc.openToast(false, 'Sorry, you cant delete primary contact.')
    }
  }

  reset() {
    this.customerContacts = [];
    this.customerLens = [];
    this.initialLoading = true;
  }

}
