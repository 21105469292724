<div class="zen-contract-det-card" [class.locked]="contract.lockedContract"
     [class.zen-status-border-color]="contract.energyPlanId === selectedEpId" [ngClass]="contract.statusCls">
  <div class="zen-status-border-color" [ngClass]="contract.statusCls">
    <div class="d-flex align-items-start">
      <i class="color-icon material-icons p-2 ms-2 me-4" [ngClass]="contract.contractStrategyCls">eco</i>
      <div class="text-start">
        <div class="d-flex align-items-center">
          <p class="text-md font-weight-500 m-0 d-flex">
            {{contract.contractStatus}} - {{contract.supplierName}} &#64;
            {{contract.supplierRate | commodityFeeFormat: {commodity: contract.commodityType, currencyType} }}
            {{contract.unit}}
          </p>
          <i class="material-symbols-rounded platform-onboarded" *ngIf="contract.lockedContract">{{ZenIcons.VERIFIED}}</i>
        </div>

        <p class="text-sm m-0">{{contract.rateTypeText}} - {{contract.contractConfiguration}}
          ({{contract.planStartDate | date: 'MMM yyyy'}} - {{contract.planEndDate | date: 'MMM yyyy'}})</p>
      </div>
    </div>
  </div>
  <div *ngIf="contract.planEndDate == null" class="draft-border-fix"></div>
  <div class="move-to-right-main">
    <i class="material-symbols-rounded color-icon no-bg pointer large "
       [popper]="contractTooltipContent" [popperDisabled]="!contract.lockedContract"
       popperTrigger="hover" popperApplyClass="xs" popperPlacement="bottom"
       (click)="onClick.emit(contract)"
    >{{contract.lockedContract ? 'lock' : 'east'}}</i>
  </div>
</div>

<popper-content #contractTooltipContent>
  <app-zen-popper title="Locked Contract" [closeable]="false" [popperContent]="contractTooltipContent">
    <p class="text-md mb-0">Contracts that are processing, or booked, through the platform cannot be selected or deselected.</p>
  </app-zen-popper>
</popper-content>
