import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {BehaviorSubject, Observable, ReplaySubject} from 'rxjs';
import { take } from 'rxjs/operators';
import { environment } from '../../../../../../environments';
import { OrganizationManagementService } from '../../_zen-legacy-common/zen-common-services/_services/organization-management.service';
import { UnitDto } from '../../_model/unit-model';

// Mark this service as injectable at the root level, meaning it will be a singleton
@Injectable({
  providedIn: 'root',
})
export class UnitService {
  // Base URL for API requests, sourced from environment configuration
  private baseUrl = environment.apiBaseUrl;

  // BehaviorSubject to store the units list and provide an initial null value
  private unitsSubject = new ReplaySubject<UnitDto[]>(1);
  // Observable version of the units subject to expose it as a public API
  units$ = this.unitsSubject.asObservable();
  private unitsLoaded = false;
  constructor(
    private http: HttpClient, // HTTP client for making API requests
    private orgMgtSvc: OrganizationManagementService // Service to get organization information
  ) {}

  /**
   * Gets the list of units for the current organization.
   * - If the units are not already loaded, it performs an API call to fetch them.
   * - The fetched units are then stored in the BehaviorSubject to keep track of the latest state.
   */
  getUnits(): Observable<UnitDto[]> {
    if (!this.unitsLoaded) {
      // If units are not loaded yet, fetch them from the API
      const organizationId = this.orgMgtSvc.getOrganizationId();
      const url = `${this.baseUrl}/v4/organizations/${organizationId}/units`;
      this.http.get<UnitDto[]>(url).pipe(take(1)).subscribe((units) => {
        // Store the fetched units in the ReplaySubject
        this.unitsSubject.next(units);
        this.unitsLoaded = true; // Set the flag indicating units have been loaded
      });
    }
    // Return the units as an observable
    return this.units$;
  }

  /**
   * Refreshes the list of units.
   * - Clears the current units by setting the BehaviorSubject to null.
   * - Fetches the latest units from the API.
   */
  refreshUnits(): void {
    // Clear the cached units
    this.unitsSubject.next(null);
    // Fetch the latest units from the API
    this.getUnits().subscribe();
  }
}
